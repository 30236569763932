import { useEffect, useState } from 'react';
import {
  FilterHeader,

} from 'views';
import { Layout } from 'components';

import API from 'services/AxiosConfig';
import { RecruitingTable } from 'views/RecruitingTable';
import { Container } from '@mui/system';

export const ProspectsGrid = (): JSX.Element => {

  const [filter, setFilter] = useState({
    flag: "false",
    location: { id: '0', value: 'All' },
    status: { id: 'Active', value: 'Active' },
    stage: { id: '', value: 'All' },
    dateRange: { id: 'All', value: 'All' },
    startDate: { id: '', value: '' },
    endDate: { id: '', value: '' },
  });
  const [options, setOptions] = useState([])
  const fetchOptions = async () => {
    let opts = await API.get('/prospects/stages')
    setOptions(opts.data.data.filter((item: any) => item.status === (filter.status.value === 'Active')))
  }
  useEffect(() => {
    fetchOptions()
  }, [filter])

  return (
    <Layout>
       <Container maxWidth="xl" sx={{ pt: 0,mt:0 }}>
        <FilterHeader type="prospectgGrid" label="Prospect Grid" setFilter={setFilter} filter={filter} options={options} />
        <RecruitingTable filter={filter} pgType='prospect' />
      </Container>
    </Layout>
  );
};
