import React , {useState, useEffect} from 'react';
import ReportingTable from '../ReportingTable';
import API from 'services/AxiosConfig';
import { formatQueryObj } from '../common';

const ProspectsLostReasonTable = ({filter}:any) => {
  const [data,setData] = useState({
    headers:[],
    rows:[]
  })
  const [loading,setLoading] = useState(false)
  

  useEffect(() => {
    setLoading(true)
    const queryObj = formatQueryObj(filter)
    API.post('/prospects/monthly-prospects-lost-client', queryObj)
          .then(({data}:any) => {
            console.log(data.data)
              setData(data?.data)
              setLoading(false)
          })
          .catch(err =>{
              console.log(err)
              setLoading(false)
          })
  }, [filter]);
  
  return <ReportingTable loading = {loading} setLoading = {setLoading} headers={data?.headers} rows={data?.rows} sumRowLabel="Total Lost Prospects" sheetName='Prospects Lost by Reason'/>
};

export default ProspectsLostReasonTable;


