import { Layout } from 'components'
// import { useAuth } from 'hooks'
// import { useEffect, useState } from 'react';
// import { FilterHeader } from 'views'
// import { ProspectTable } from 'views/Prospects/ProspectsTable'
// import { useFilter } from '../ReferralPartners/ReferralFilterContext'
// import { fetchProspectStages } from 'lib';
import { Container } from '@mui/system';
import ProspectPagetabs from 'views/Prospects/ProspectPagetabs';
// function Prospects() {
// const { user } = useAuth()

// const { prospectFilter, updateProspectFilter } = useFilter()
// const [stages, setStages] = useState<any[]>([]);
    
//   useEffect(() => {
//     fetchProspectStages(setStages);
//   }, []);
//     return (
//         <Layout>
//             <div style={{ paddingTop: '13vh' }}>
//                 <FilterHeader type="prospects" setFilter={updateProspectFilter} filter={prospectFilter} options={stages}/>
//             </div>
//             <ProspectTable filter={prospectFilter} setFilter={updateProspectFilter} title="Prospects" />
//         </Layout>
//     )
// }

// export default Prospects


const Prospects = () => {
  return (
    <Layout>
      <div>
        <Container maxWidth={false} sx={{ paddingTop: 15, paddingLeft: '0' }}>
          <ProspectPagetabs  HeaderTitle={"Prospects"} ></ProspectPagetabs>
        </Container>
      </div>
    </Layout>
  );
};

export default Prospects;
