import React, { useEffect, useRef, useState } from "react";
import {
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  Button,
  Tooltip
} from "@mui/material";
import * as XLSX from 'xlsx';
import { NoData, Spinner } from "components";

interface RowData {
  label: string;
  values: Array<number | string>;
}

interface TableProps {
  headers: string[];
  rows: RowData[];
  sumRowLabel: string;
  sheetName?: string
  loading?: boolean
  setLoading?:any
}

const renderFirstColumn = (row: RowData, index: number) => (
  <TableRow key={index}>
    <TableCell align="left">{row.label}</TableCell>
  </TableRow>
);

const renderOtherColumns = (row: RowData, index: number) => (
  <TableRow key={index}>
    {row.values.map((value, i) => (
      <TableCell  key={i} align="right">{value}</TableCell>
    ))}
  </TableRow>
);

const ReportingTable: React.FC<TableProps> = ({ headers, rows, sumRowLabel, sheetName, loading,setLoading }) => {

  const calculateSum = (data: RowData[]) => {
    let sumArr = new Array(data[0]?.values?.length).fill(0);
    data.forEach(row =>
      row.values.forEach((value, index) => {
        if (typeof value === "number") {
          sumArr[index] += value;
        }
      })
    );
    return sumArr;
  };
  const firstColumnRef = useRef<HTMLTableElement | null>(null);

  const [firstTableWidth, setFirstTableWidth] = useState('auto');

  useEffect(() => {
    if (firstColumnRef.current) {
      let maxWidth = 0;
      // Iterate over each cell to find the maximum width
      Array.from(firstColumnRef.current.querySelectorAll('tr')).forEach(tr => {
        maxWidth = Math.max(maxWidth, tr.clientWidth);
      });
      setFirstTableWidth(`${maxWidth}px`);
    }
  }, [rows]);

  const setColumnWidths = (ws: any, numOfCols: number, defaultWidth: number) => {
    const colWidths: any = Array.from({ length: numOfCols }, () => ({ wpx: defaultWidth }));
    colWidths[0] = { wpx: 175 }
    ws['!cols'] = colWidths;
  };


  const exportToExcel = () => {
    setLoading(true)
    const copy = rows.slice()
    copy.push({label:sumRowLabel,values:calculateSum(copy)})
    const ws = XLSX.utils.json_to_sheet(copy.map(row => {
      let obj: { [key: string]: number | string } = {};
      // Use the first value of headers for the label
      obj[headers[0]] = row.label;
      // Map values to the other headers
      row.values.forEach((value, index) => {
        obj[headers[index + 1]] = value === 0 ? '-' : value; // '+1' to skip the first header
      });
      return obj;
    }));
    setColumnWidths(ws, headers.length, 75);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${sheetName}.xlsx` || "data.xlsx");
    setLoading(false)
  }

  const renderRow = (row: RowData, index: number) => (
    <TableRow key={index}>
      {row.values.map((value, i) => (
        <TableCell key={i} align="right">{value === 0 ? '-' : value}</TableCell>
      ))}
    </TableRow>
  );

  const renderSumRow = (label: string, data: RowData[]) => {
    let sumValues = calculateSum(data);
    return (
      <TableRow style={{ borderTop: '2px solid black', fontWeight: 'bold' }}>
        {sumValues.map((value, index) => (
          <TableCell key={index} align="right" style={{ fontWeight: 'bold' }} >{value}</TableCell>
        ))}
      </TableRow>
    );
  };

  return (
    <div style={{ maxWidth: '1700px', width: '100%',borderRadius:'4px 0 0 4px' }}>
      <Button variant="contained" color="primary" style={{ float: 'right', margin: 0, marginBottom: '5px' }} disabled={rows.length <= 0} onClick={exportToExcel} >
        Download Data
      </Button>
      <div style={{  display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', width: '100%', maxWidth: '1700px'}}>
        <TableContainer  ref={firstColumnRef} component = {Paper} style={{flexBasis: '50%', flexGrow: 1, maxWidth: '40%', overflow: 'hidden', borderRadius:'4px 0 0 4px' ,}}>
          <MuiTable>
            <TableHead>
              <TableRow>
                <TableCell style={{ backgroundColor: '#2f374e', color: 'white', minWidth: '500px',height:'25px' }}>{headers[0] || sheetName}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                loading ? (
                  // Render only the Spinner when loading
                  <TableRow>
                    <TableCell
                      colSpan={Math.min(headers.length * 2, 18)}
                      style={{ textAlign: 'center', padding: '20px' }}
                    >
                      <Spinner />
                    </TableCell>
                  </TableRow>
                ) : rows.length === 0 ? (
                  // Render 'No Data' component or message if there are no rows
                  <TableRow style={{ width: '100%' }}>
                    <TableCell
                      colSpan={Math.min(headers.length * 2, 18)}
                      style={{ textAlign: 'center', padding: '20px' }}
                    ><NoData /></TableCell>
                  </TableRow>
                ) : (
                  // Render the table rows when there are rows available
                  <>
                    {rows.map((row, i) => renderFirstColumn(row, i))}
                    <TableRow sx={{ borderTop: "2px solid black" }} ></TableRow>
                    <TableRow sx={{ borderTop: "2px solid black" }}>
                      <TableCell align="left" style={{ fontWeight: 'bold' }}>{sumRowLabel}</TableCell>
                    </TableRow>
                  </>
                )
              }
            </TableBody>
          </MuiTable>
        </TableContainer>
        <TableContainer  style = {{ flexBasis: '50%', flexGrow: 1, maxWidth: '60%', borderRadius: '0 4px 4px 0'}}  component = {Paper} >
          <MuiTable>
            <TableHead>
              {loading ?   <TableRow style = {{minWidth:'100%'}}>
                {['Jun-23','Jul-23','Aug-23','Sep-23','Oct-23','Nov-23'].map((header, i) => (
                  <TableCell style={{ backgroundColor: '#2f374e', color: 'white', minWidth: '75px' ,height:'25px' }} key={i} align={'right'}>{header}</TableCell>
                ))}
              </TableRow> :
              <TableRow>
                {headers.slice(1).map((header, i) => (
                  <TableCell style={{ backgroundColor: '#2f374e', color: 'white', minWidth: '75px',height:'25px'  }} key={i} align={'right'}>{header}</TableCell>
                ))}
              </TableRow>}
            </TableHead>
            <TableBody>
              {
                loading ? (
                  // Render only the Spinner when loading
                  <TableRow>
                    <TableCell
                      colSpan={Math.min(headers.length * 2, 18)}
                      style={{ textAlign: 'center', padding: '20px' }}
                    >
                      <Spinner />
                    </TableCell>
                  </TableRow>
                ) : rows.length === 0 ? (
                  // Render 'No Data' component or message if there are no rows
                  <TableRow style={{ width: '100%' }}>
                    <TableCell
                      colSpan={Math.min(headers.length * 2, 18)}
                      style={{ textAlign: 'center', padding: '20px' }}
                    ><NoData /></TableCell>
                  </TableRow>
                ) : (
                  // Render the table rows when there are rows available
                  <>
                    {rows.map((row, i) => renderRow(row, i))}
                    <TableRow sx={{ borderTop: "2px solid black" }} />
                    {renderSumRow(sumRowLabel, rows)}
                  </>
                )
              }
            </TableBody>
          </MuiTable>
        </TableContainer>
      </div>
    </div>
  );
};

export default ReportingTable;