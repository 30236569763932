import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { CircularProgress } from '@mui/material';
import { Spinner } from 'components/Spinner';
import Paper from '@mui/material/Paper';
import {
  PagingState,
  IntegratedPaging,
  SortingState,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import _ from 'lodash'
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { GrFlagFill } from 'react-icons/gr';
import { IconButton } from '@mui/material';
import { NavLink } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

function ResizeTable({
  rows,
  columns,
  sorting,
  setSorting,
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  columnWidths,
  handleColumnWidthsChange,
  pageSizes,
  loading = true,
  renderCompanyUrl = false
}) {

  const isSortingEnabled = (columnName) => {
    return true
  };


  const TableHeaderCell = ({ column, ...restProps }) => {
    return (
      <TableHeaderRow.Cell {...restProps} columnSortingEnabled={false}>
        {column.name !== '_id' ? (
          <Fragment>
            {restProps.children}
          </Fragment>
        ) : (
          <></>
        )}
      </TableHeaderRow.Cell>
    )
  }

  const TableRow = ({ row, ...restProps }) => (


    <Table.Row {...restProps}
    >

    </Table.Row>
  );

  const TableCell = ({ column, value, ...restProps }) => (

    <Table.Cell {...restProps} style={column.name === '_id' ? { display: 'flex', justifyContent: 'flex-end', } : {}}>
      {((column.name === 'flagged' || column.name === 'flag') && value) ? <GrFlagFill color="red" /> : (column.name === '_id') ? <NavLink to={renderCompanyUrl ? `/marketing/company/${value}` : `/marketing/referral-partners/${value}`}><IconButton
        sx={{
          bgcolor: 'primary.main',
          color: 'white',
        }}
        aria-label="Edit Item "
      >
        <AccountCircleIcon />
      </IconButton></NavLink> : value || '-'}

    </Table.Cell>
  )


  return (
    <Paper style={{ overflowX: 'auto' }}>
       {loading && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          zIndex: 2
        }}>
          <CircularProgress />
        </div>
      )}
     <Grid rows={loading ? [] : rows} columns={columns}>
        <SortingState
          sorting={sorting}
          onSortingChange={setSorting}

        />
        <IntegratedSorting />
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSize === 'All' ? rows.length : pageSize}
          onPageSizeChange={(size) => {
            setPageSize(size);
            if (size === 'All') {
              setCurrentPage(0);
            }
          }}
        />
        <IntegratedPaging />
        <Table rowComponent={TableRow} cellComponent={TableCell}  />
        <TableColumnResizing
          columnWidths={columnWidths || columns.map((item) => ({ columnName: item.title, width: 100 }))}
          onColumnWidthsChange={handleColumnWidthsChange}
        />
        <TableHeaderRow showSortingControls cellComponent={TableHeaderCell} />
        <PagingPanel pageSizes={pageSizes} />
      </Grid>
    </Paper>
  );
}

export default ResizeTable;