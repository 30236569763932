import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(...registerables);

const BarChart = (props: any) => {
if(props?.renderLabels === true){
    return(
        <Bar
        data={props.chartData}
        width={100}
        height={75}
        options={props.options}
        plugins={[ChartDataLabels]}

    />   
    )
}

return (
    <Bar
        data={props.chartData}
        width={100}
        height={75}
        options={props.options}
    />
)
}

export default BarChart;