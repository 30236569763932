import { useContext, createContext, useState, FC, useEffect } from 'react';
import { useAuth } from 'hooks';
export interface FilterStateI {
  flag: boolean;
  referralPartner: { id: string; value: string };
  companyName: { id: string; value: string };
  status: { id: string; value: string };
  companyType: { id: string; value: string };
  location: { id: string; value: string };
  groupBy: { id: string; value: string };
}

export interface ProspectFilterStateI {
  flag: boolean;
  prospect: { id: string; value: string };
  location: { id: string; value: string };
  status: { id: string; value: string };
  dateRange: { id: string; value: string };
  startDate: { id: string; value: string };
  endDate: { id: string; value: string };
  stage: { id: string; value: string };
  groupBy: { id: string, value: string },
  milestone:  { id: string, value: string },
}

export interface ApplicantsFilterStateI {
  flag: boolean,
  name: { id: string, value: string },
  location: { id: string, value: string },
  status: { id: string, value: string },
  stage: { id: string, value: string },
  dateRange: { id: string, value: string },
  startDate: { id: string, value: string },
  endDate: { id: string, value: string },
}
interface Context {
  referralFilter: FilterStateI;
  prospectFilter: ProspectFilterStateI;
  applicantsFilter: ApplicantsFilterStateI;
  updateFilter: (newFilter: Partial<FilterStateI>) => void;
  updateProspectFilter: (newFilter: Partial<ProspectFilterStateI>) => void;
  resetProspectFilter: () => void;
  resetFilter: () => void;
  updateApplicantsFilter: (newFilter: Partial<ApplicantsFilterStateI>) => void;
  resetApplicantsFilter: () => void;
}

const initialFilterState: FilterStateI = {
  flag: false,
  referralPartner: { id: '', value: '' },
  companyName: { id: '', value: '' },
  status: { id: 'Active', value: 'Active' },
  companyType: { id: 'All', value: 'All' },
  location: { id: '', value: '' },
  groupBy: { id: 'None', value: 'None' },
};

const initialProspectFilterState: ProspectFilterStateI = {
  flag: false,
  prospect: { id: '', value: 'All' },
  location: { id: 'All', value: 'All' },
  status: { id: 'Open', value: 'Active' },
  dateRange: { id: 'All', value: 'All' },
  startDate: { id: '', value: '' },
  endDate: { id: '', value: '' },
  stage: { id: 'All', value: 'All' },
  groupBy: { id: 'None', value: 'None' },
  milestone:   { id: 'All', value: 'All' },
}
const initialApplicantsFilterState: ApplicantsFilterStateI = {
  flag: false,
  name: { id: '', value: '' },
  location: { id: '', value: '' },
  status: { id: 'Active', value: 'Active' },
  stage: { id: '', value: 'All' },
  dateRange: { id: 'All', value: 'All' },
  startDate: { id: '', value: '' },
  endDate: { id: '', value: '' },
}

export const FilterContext = createContext<Context | undefined>(undefined);

export const FilterProvider: FC = ({ children }) => {
  const { user } = useAuth()
  const [filter, setFilter] = useState<FilterStateI>({ ...initialFilterState, location: { id: user?.location?._id, value: user?.location?.value } });
  const [prospectFilter, setProspectFilter] = useState<ProspectFilterStateI>({ ...initialProspectFilterState, location: { id: user?.location?._id, value: user?.location?.value } })
  const [applicantsFilter, setApplicantsFilter] = useState<ApplicantsFilterStateI>({ ...initialApplicantsFilterState, location: { id: user?.location?._id, value: (user?.location?.value || user?.location?.location) } })
  const updateFilter = (newFilter: Partial<FilterStateI>) => {
    setFilter(prevFilter => ({ ...prevFilter, ...newFilter }));
  };

  const resetFilter = () => {
    setFilter({ ...initialFilterState, location: { id: user?.location?._id, value: user?.location?.value } });
  };
  const updateProspectFilter = (newFilter: Partial<ProspectFilterStateI>) => {
    setProspectFilter(prevFilter => ({ ...prevFilter, ...newFilter }));
  };

  const resetProspectFilter = () => {
    setProspectFilter({ ...initialProspectFilterState, location: { id: user?.location?._id, value: user?.location?.value } });
  };

  const updateApplicantsFilter = (newFilter: Partial<ApplicantsFilterStateI>) => {
    setApplicantsFilter(prevFilter => ({ ...prevFilter, ...newFilter }))
  }

  const resetApplicantsFilter = () => {
    setApplicantsFilter({ ...initialApplicantsFilterState, location: { id: user?.location?._id, value: (user?.location?.value || user?.location?.location) } })
  }

  useEffect(() => {
    setFilter(prevFilter => ({ ...prevFilter, location: { id: user?.location?._id, value: user?.location?.value } }));
    setApplicantsFilter(prevFilter => ({ ...prevFilter, location: { id: user?.location?._id, value: user?.location?.location } }));
  }, [user])

  return (
    <FilterContext.Provider value={{ referralFilter: filter, prospectFilter, applicantsFilter, updateFilter, resetFilter, updateProspectFilter, resetProspectFilter, updateApplicantsFilter, resetApplicantsFilter }}>
      {children}
    </FilterContext.Provider>
  );
};

export function useFilter() {
  const context = useContext(FilterContext);


  if (!context) {
    return {
      referralFilter: initialFilterState,
      prospectFilter: initialProspectFilterState,
      applicantsFilter: initialApplicantsFilterState,
      updateFilter: (newFilter: Partial<FilterStateI>) => { },
      updateProspectFilter: (newFilter: Partial<ProspectFilterStateI>) => { },
      resetFilter: () => { },
      resetProspectFilter: () => { },
      updateApplicantsFilter: () => { },
      resetApplicantsFilter: () => { }
    }
  } else {
    return context;
  }
}