
import { useEffect, useState ,useRef} from 'react'
import FullCalendar, { EventClickArg, EventContentArg,CalendarApi } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Card, CardHeader, Modal, NoData, Spinner } from 'components'
import { colorCode, formatName } from 'lib'
import CareManagerActivitiesModalContent from 'views/CareManagerActivities/CareManagerActivitiesModalContent'
import rrulePlugin from '@fullcalendar/rrule'
import { generateMarketingRows } from 'views/CareManagerActivities/generateRows'
import { useCompany,useAuth } from 'hooks'
import { RRule } from 'rrule'
import DoneIcon from '@mui/icons-material/Done';
import moment, { tz } from 'moment-timezone'
import { IconButton } from '@mui/material'
//import MarketingCalendarTasksModal from './MarketingCalendarTasksModal'
import MarketingActivitiesModalContent from './MarketingActivitiesModalContent'
import { toast } from 'react-toastify';
import API from 'services/AxiosConfig';
interface careManagerCalendarProps {
  activities?: any
  fetchData: any
}

export function MarketingCalendar({ activities, fetchData }: careManagerCalendarProps) {
  const { locations } = useCompany()
  const [events, setEvents] = useState<any>()
  const [openModal, setOpenModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState<any>()
  const [scrollPosition, setScrollPosition] = useState<any>()
  
  const calendarRef = useRef<FullCalendar | null>(null);
  const { user } = useAuth();
  const handleCloseModal = () => {
    fetchData()
    setOpenModal(false)
    setScrollPosition(window.scrollY.toString());
  }
  useEffect(() => {
    if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'manual';
    }
}, []);

  const formatArrays = (arr: any, type: any) => {
    if (!arr) return ''
    let resultStr = ''
    arr.map((item: any) => {
      const name = type === 'company' ? item.companyName : formatName(item.firstName, item.lastName)

      resultStr += name + '\n'
    })
    return resultStr
  }


  const generateEventTitle = (activityObj: any) => {
    const activityType = activityObj?.activity?.type
    // let eventGuest = activityObj.activity.activity[1] === 'P' ? `${activityObj?.carePartner?.firstName || ''} ${activityObj?.carePartner?.lastName || ''}` : `${activityObj?.client?.firstName || ''} ${activityObj?.client?.lastName || ''}`
    let eventGuest = formatArrays(activityObj?.referralPartner, 'partner')
    if (eventGuest === "") {
      eventGuest = activityObj?.prospect?.fullName
    }
    let eventHost = formatName(activityObj?.marketingManager?.firstName, activityObj?.marketingManager?.lastName)
    return `${eventGuest} / ${eventHost} : ${activityType}`

  }

  const generateEvents = (data: any) => {
    data && setEvents(data.map((activityObj: any, i: number) => {
      let currentDate = activityObj.completed ? activityObj?.completedDate : activityObj?.date
      const formattedDate = currentDate ? moment(currentDate.replace(/-/g, '\/').replace(/T.+/, '')) : moment()


      let allDayEvent = false;

      const condition = activityObj.completed ? (activityObj.completedTime && activityObj.completedTime !== "") : (activityObj.time && activityObj.time !== '')

      if (condition) {
        const time = moment(activityObj.completed ? activityObj?.completedTime : activityObj.time);
        formattedDate.set({
          hour: time.get('hour'),
          minute: time.get('minute'),
        });
      } else {
        allDayEvent = true;
      }

      const newestDate = new Date(formattedDate.utc().toISOString())

      let event: any = {
        title: generateEventTitle(activityObj),
        id: i,
        date: newestDate,
        allDay: allDayEvent,
        textColor: colorCode(activityObj.date),
        extendedProps: { completed: activityObj.completed, activityObj, time: activityObj.time }
      }


      let today = moment()
      // activityObj?.recurrence?.type && activityObj?.recurrence?.type !== "No Recurrence" && (event.rrule = {
      //   freq: activityObj?.recurrence?.type === 'Monthly' ? RRule.MONTHLY : activityObj?.recurrence?.type|| '',
      //   interval: Number(activityObj.recurrence.options),
      //   dtstart: newestDate,
      //   tzid:'Etc/GMT+0',
      //   until: moment(activityObj.recurrence.endDate).add(1, 'day') || today.add(1, 'y'),

      // })
      // if (activityObj?.recurrence?.type === 'Weekly') {
      //   activityObj?.recurrence?.days.length > 0 && (event.rrule.byweekday = activityObj?.recurrence?.days)
      // }

      return event
    }))
    if (calendarRef.current && selectedRow) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(new Date(selectedRow.date.value));
      setTimeout(() => {
         window.scrollTo(0, scrollPosition || 0);
      }, 500);
    }
  }


  const eventTimeFormat = (event: any) => {
    const date = moment.utc(event.start.marker).utcOffset(event.start.timeZoneOffset / 60);
    const formattedTime = date.tz('Etc/GMT+0').format('h:mm A');
    return formattedTime;
  };
  const renderEventContent = (eventContent: EventContentArg) => {

    return (
      <div className="hover event-content" >
        {eventContent?.event?._def?.extendedProps?.completed && <IconButton
          sx={{
            background: 'green',
            color: 'white',
            cursor: "default",
            height: '1em',
            width: '1em'
          }}
          disableRipple
          disableFocusRipple
          className="hired"
          aria-label="Hired"
        >
          <DoneIcon />
        </IconButton>} <strong className='event-time'>{eventContent.timeText}</strong>
        <p style={{ fontSize: 'larger', color: eventContent?.event?._def?.extendedProps?.completed ? 'Black' : eventContent.textColor, margin: '5px', }}>{eventContent.event.title}</p>
      </div>
    )
  }

  const handleEventClick = (clickInfo: EventClickArg) => {
    let selRow = generateMarketingRows([activities[clickInfo.event._def.publicId]])[0];
    if(selRow?.state?.value?.completed==false)
      {
        delete selRow?.state?.value?.completedDate;
      }
   
    setSelectedRow(selRow);
    console.log('this is now the row',selRow)
    setOpenModal(true)
  }

  useEffect(() => {
    generateEvents(activities)
    return () => { }
  }, [activities])

  const updateEventDate = (values: any,date:any) => {
    //toast.loading('Updating care manager task...');

    API.put(`/marketing/update-date`, { date: date, id: values._id, userId: user?._id })
      .then((rsp) => {
        toast.dismiss();
        if (rsp.data.success) {
          
          //setTimeout(() => {
            window.scrollTo(0, scrollPosition || 0);
            toast.success('Successfully updated care manager task.');
            fetchData()
         //}, 500);
        }
      })
      .catch((error) => {
        toast.dismiss();
        toast.error('Failed to edit care manager task.');
        console.error(error);
      });
  };
  const handleEventDrop = (info:any) => {
  
    const updatedEvents = events.map((event:any) => {
      if (event.id === parseInt(info.event.id, 10)) {
        //api call 
        //setScrollPosition(window.scrollY.toString());
        window.scrollTo(0, scrollPosition || 0);
        updateEventDate(info.event.extendedProps.activityObj,info.event.startStr);
        return {
          ...event,
          start: info.event.startStr
        };
      }
      return event;
    });
    setEvents(updatedEvents);
  };
  const handleEventDragStart = () => {
    setScrollPosition(window.scrollY.toString());
  };
  return (
    <Card style ={{marginBottom:'10px',marginTop:'10px'}} >
      <CardHeader title="Calendar Report" setOpenModal={() => { }} expanded={true} setExpanded={() => { }} addIcon={false} expandable={false} />
      <Modal open={openModal} closeHandler={handleCloseModal} title="Edit Marketing Task" radioGroup={true} radioGroupLabel1="Completed" radioGroupLabel2='Incomplete' currentRow={selectedRow}>
        <MarketingActivitiesModalContent closeHandler={handleCloseModal} selected={selectedRow} data={selectedRow} renderButtons management={true} source='marketingCalendar' />
        {/* <MarketingTasksModalContent closeHandler={handleCloseModal} selected={selectedRow} data={selectedRow} renderButtons management={true} source='marketingCalendar' /> */}
        {/* <MarketingCalendarTasksModal closeHandler={handleCloseModal} selected={selectedRow} data={selectedRow} renderButtons management={true} source='marketingCalendar' /> */}
      </Modal>
      {!events && <Spinner />}
      {events?.length === 0 && <NoData />}
      {events?.length > 0 && locations.length > 0 &&
        <FullCalendar
          plugins={[dayGridPlugin, rrulePlugin,timeGridPlugin,interactionPlugin]}
          headerToolbar={{
            left: 'prev,next',
            center: 'title',
            right: 'today,dayGridWeek,dayGridMonth'
          }}
          contentHeight='auto'
          selectable={false}
          initialView='dayGridMonth'
          editable={true}
          weekends={true}
          events={events}
          eventContent={renderEventContent} // custom render function
          eventClick={handleEventClick}
          eventTimeFormat={eventTimeFormat}
          ref={calendarRef}
          eventDrop={handleEventDrop}
          eventDragStart={handleEventDragStart}
        />
      }
    </Card>
  )
}

