import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Typography, Select, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { saveDocument } from '../Uploader/redux/actions/documentActions'; // Adjust the path to where your API actions are defined
import { useAuth } from 'hooks';

const AddFolderForm = ({ open, onClose, onFolderAdded, category }) => {
  const [folderName, setFolderName] = useState('');
  // const [category, setSelectedValue] = useState('all');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  // const [uploadSections, setUploadSections] = useState([]);
  const dispatch = useDispatch();
  // const { fileSections, fetchDocumentsLoading } = useSelector((state) => state.documents);
  const { user } = useAuth();
  // useEffect(() => {
  //   if (fileSections.length > 0) {
  //     const mappedData = fileSections.map(item => ({
  //       key: item._id,
  //       label: item.type
  //     }));
  //     const d = [{ key: "66d154e6all259a3833fo474", label: 'All' }, ...mappedData]
  //     setUploadSections(d);
  //   }
  // }, [fileSections, fetchDocumentsLoading, dispatch]);
  const handleSubmit = async () => {
    if (!folderName.trim()) {
      setError('Folder name is required');
      return;
    }

    setLoading(true);
    setError('');

    const data = {
      category: category,
      dtype: "folder",
      location: "63ac458916769ea5183d3441",
      comments: "",
      pathUrl: "",
      fileKey: "",
      parent: selectedNode || '',
      name: folderName,
      createdBy: user._id
    };

    try {
      dispatch(saveDocument(data));
      setFolderName('');
      //setSelectedValue('all');
      onFolderAdded();
      onClose();
    } catch (error) {
      setError('Failed to create folder. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  const selectedNode = useSelector((state) => state.documents.selectedNode);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Folder</DialogTitle>
      <DialogContent>
        {/* {selectedNode && <Typography variant="body1" sx={{ mt: 2, fontSize: '12px' }}>
          {`ID: ${selectedNode}`}
        </Typography>} */}
        <Box>
          {/* <Select
            labelId="user-role-select-label"
            id="user-role-select"
            value={category}
            onChange={(e) => setSelectedValue(e.target.value)}
            fullWidth
            margin="dense"
            variant="outlined"
          >
            {uploadSections.map(item => (
              <MenuItem key={item.key} value={item.key}>
                {item.label}
              </MenuItem>
            ))}
          </Select> */}
          <TextField
            autoFocus
            margin="dense"
            label="Folder Name"
            fullWidth
            variant="outlined"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            error={!!error}
            helperText={error}
          />
          {loading && (
            <Box display="flex" justifyContent="center" mt={2}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={loading}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddFolderForm;
