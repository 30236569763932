import { useState, useEffect, useMemo } from 'react';
import DateRange from '@mui/icons-material/DateRange';
import {

    Box,
    Button,

} from '@mui/material';
function StagePopup({ handleCalendar, progressData, prospectId, closePopup }: any) {
    return (
        <Box>
            <div className="dropdown-stage">
                {progressData.map((item: any, index: number) => (
                    <div key={item.cardName.stage + index} className="dropdown-stage-group">
                        <div className="dropdown-stage-header" style={{ borderBottom: '1px solid #ccc', fontWeight: "500", fontSize: "16px" ,color:'#616161'}}>
                            {item.cardName.stage}
                        </div>
                        <div className="dropdown-stage-items">
                            {item.cardItems.map((citem: any, childIndex: number) => (
                                <div
                                    key={citem._id}
                                    className="dropdown-stage-item"
                                    onClick={() =>
                                        handleCalendar(true, {
                                            index: childIndex,
                                            parnetIndex: index,
                                            prospectId: prospectId,
                                            label: citem?.label,
                                            fullDate: citem?.value,
                                            stageId: citem._id,
                                            parentLabel:item?.cardName?.stage,
                                            psid:item._id

                                        })
                                    }
                                >
                                    <div className="dropdown-stage-item-icon" style={{color:'#616161'}}>
                                        <DateRange />
                                    </div>
                                    <div className="dropdown-stage-item-text" style={{color:'#616161'}}>
                                        {citem?.label + (citem?.value ? " (" + citem?.value?.slice(5, 10) + ")" : "")}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
                
            </div> 
            <Box>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Button onClick={() => closePopup()} variant="contained" sx={{ bgcolor: '#a3a3ab' }} type="button">
                Cancel
            </Button>
            <Button onClick={() => closePopup()} variant="contained" sx={{ bgcolor: '#5b73e8' }} type="button">
                Submit
            </Button></div></Box>

            </Box>)
}

export default StagePopup