import { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormControl, Box, InputLabel, Button, Stack, Select, MenuItem, Fab, Autocomplete, TextField, IconButton } from '@mui/material';
import API from '../../services/AxiosConfig';
import { toast } from 'react-toastify';
import { useAuth, useCompany } from 'hooks';
import { FormDatePicker, FormInput, FormTimePicker } from 'components';
import { formatDate, formatName } from 'lib';
import { useParams } from 'react-router-dom';
import { FormHelperText } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
interface editApplicantsProps {
  closeMe: () => void;
  currentRow?: any;
  options: any;
  batchAdd: string;
}

const ActivtyModalContent = ({ closeMe, currentRow, options, batchAdd }: editApplicantsProps) => {
  const { users } = useCompany();
  const { user } = useAuth();
  const userId = useParams();
  const [addedRows, setAddedRows] = useState<any>([]);
  const [renderP, setRenderP] = useState(false);
  const [applicantsAll, setApplicantsAll] = useState<any>([]);

  const [selApplicants, setSelApplicants] = useState<any>({ id: '', firstName: '', lastName: '' });
  const editApplicant = async (values: any, sensitiveId: any) => {
    API.put('applicants/activities/' + sensitiveId, { ...values, modifiedBy: user.id })
      .then((rsp) => {
        if (rsp.data.success) {
          toast.success('Successfully Updated Applicant!');
          closeMe();
        }
      })
      .catch((err) => { });
  };

  const createApplicant = (values: any) => {
    API.post('/applicants/activities', { ...values, applicant: userId.id, createdBy: user.id })
      .then((rsp) => {
        if (rsp.data.success) {
          //rsp.data._id
          toast.success('Successfully Added Applicant.');
          closeMe();
        }
      })
      .catch((error) => {
        toast.error('Failed to add applicant.');
        console.error(error);
      });
  };
  const fetchApplicants = async () => {

    const location = currentRow?.state?.location || user.location;
    const applicants = await API.get(`/applicants/ddn/${location._id}`);
    const _applicant = applicants?.data?.data?.find((element: any) => {
      return element._id === userId.id;
    })
    setApplicantsAll(applicants?.data?.data);
    setSelApplicants(_applicant);
  }
  const removeRows = (idx: any) => {
    let newObj: any = { ...formik.values };
    setAddedRows(addedRows.filter((item: any, indx: any) => indx !== idx))
    newObj = deleteAndRenumber(newObj, `applicant${idx + 1}`, 'applicant');
    formik.setValues(newObj)
  }
  const deleteAndRenumber = (data: any, keyToDelete: string, prefix: string) => {
    const newObj: any = { ...data };
    let pvalue: string = "";
    pvalue = newObj[prefix];
    delete newObj[keyToDelete];

    const keys = Object.keys(newObj)
      .filter((key) => key.startsWith(prefix) && key != prefix)
      .sort((a, b) => {
        const numA = parseInt(a.replace(prefix, '').match(/\d+$/)?.[0] || '0', 10);
        const numB = parseInt(b.replace(prefix, '').match(/\d+$/)?.[0] || '0', 10);
        return numA - numB;
      });

    const renumberedObj: any = { ...newObj };
    keys.forEach((key, index) => {
      const newKey = prefix + (index + 1);
      renumberedObj[newKey] = newObj[key];
      if (newKey !== key) {
        delete renumberedObj[key];
      }
    });
    if (pvalue != "") {
      renumberedObj[prefix] = pvalue;
    }
    return renumberedObj;
    //formik.setValues(renumberedObj)
  };
  const validationSchema = yup.object({
    date: yup.date().required('Date is required'),
    completedBy: yup.string().required('Completed by is required'),
    activity: yup.string().required('Activity is required'),
  });
  let initialValues: any =
  {
    time: currentRow?.state?.value?.time || '',
    date: currentRow?.date?.value || '',
    activity: currentRow?.activity?.id || '',
    completedBy: currentRow?.completedBy?.id || user._id,
    comments: currentRow?.comments?.value || '',
    applicant: [],
  }
  const formik = useFormik({
    // initialValues: {
    //   time: currentRow?.state?.value?.time || '',
    //   date: currentRow?.date?.value || '',
    //   activity: currentRow?.activity?.id || '',
    //   completedBy: currentRow?.completedBy?.id || user._id,
    //   comments: currentRow?.comments?.value || '',
    //   applicant:[],
    // },
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      currentRow ? editApplicant(values, currentRow.id) : createApplicant(values);
      closeMe();
    },
  });
  useEffect(() => {
    const current = new Date()
    !currentRow?.date?.value && formik.setFieldValue('date', current)
    !currentRow?.state?.value?.time && formik.setFieldValue('time', current)
    setRenderP(true)
    fetchApplicants()

  }, [])

  return (
    <>
      <Box sx={{ paddingTop: '20px' }}>
        <h2 className="fs-30 pt">{currentRow ? 'Edit' : 'Add'} Activity </h2>
        <form onSubmit={formik.handleSubmit}>
          <Stack>
            <Box
              sx={{
                paddingTop: '20px',
                display: 'grid',
                gridTemplateColumns: 'auto auto',
                gap: '15px',
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="Activity-label">Activity</InputLabel>
                <Select
                  id="demo-simple-select"
                  value={formik.values.activity}
                  label="Activity"
                  name="activity"
                  onChange={formik.handleChange}
                  error={formik.touched.activity && Boolean(formik.errors.activity)}
                >
                  {options.map(({ _id, type }: any) => (
                    <MenuItem value={_id}>{type}</MenuItem>
                  ))}
                </Select>
                {formik.touched.activity && formik.errors.activity && <FormHelperText error >Completed By required</FormHelperText>}
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="Completed-By-label">Completed By*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formik.values.completedBy}
                  label="Completed By"
                  name="completedBy"
                  onChange={formik.handleChange}
                  error={formik.touched.completedBy && Boolean(formik.errors.completedBy)}
                >
                  {users.map((user: any) => (
                    <MenuItem value={user._id}>{formatName(user.firstName, user.lastName)}</MenuItem>
                  ))}
                </Select>
                {formik.touched.completedBy && formik.errors.completedBy && <FormHelperText error >Completed By required</FormHelperText>}
              </FormControl>
              <FormDatePicker
                name="date"
                label="Date"
                value={formik.values.date}
                onChange={formik.handleChange}
                error={formik.touched.date && Boolean(formik.errors.date)}
                helperText={formik.touched.date && formik.errors.date}
                pickerOnChange={(newValue: String | null) => {
                  if (newValue) {
                    formik.setFieldValue('date', formatDate(newValue.toString()));
                  }
                }}
                required
              />
              <FormTimePicker
                name="time"
                label="Time"
                value={formik.values.time}
                onChange={formik.handleChange}
                error={formik.touched.time && Boolean(formik.errors.time)}
                helperText={formik.touched.time && formik.errors.time}
                pickerOnChange={(newValue) => {
                  if (newValue) {
                    formik.setFieldValue('time', newValue);
                  }
                }}
              />
              <div className='activity-modal-comments'>
                <FormInput
                  labelProps={{
                    shrink: true,
                  }}
                  type="text"
                  name="comments"
                  label="Comments"
                  value={formik.values.comments}
                  onChange={formik.handleChange}
                  error={formik.touched.comments && Boolean(formik.errors.comments)}
                  helperText={formik.touched.comments && formik.errors.comments}
                  textarea
                />
              </div>
              { !currentRow &&(
              <div style={{ /*display: 'flex', alignItems: 'center',*/ justifyContent: 'space-between', marginTop: '10px' }}>
                <FormControl sx={{ width: '80%' }}>
                  <Autocomplete
                    value={selApplicants}
                    options={
                      [
                        ...applicantsAll.map((user: any) => ({ id: user._id, firstName: user?.firstName, lastName: user?.lastName }))
                      ]
                    }
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    onChange={(event: any, newValue: any | null) => {
                      if (null || !newValue) {
                        formik.setFieldValue(`applicant`, '');
                      }
                      else {
                        formik.setFieldValue(`applicant`, newValue._id)
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Applicant" />
                    )}
                  />
                </FormControl>
                <Fab
                  style={{ float: 'right', height: '40px', width: '40px', marginRight: '5px' }}
                  color="primary"
                  aria-label="add"
                  onClick={() => {
                    const len = addedRows.length;
                    setAddedRows((addedRows: any) => [...addedRows, null])
                    formik.setValues({ ...formik.values, [`applicant${len + 1}`]: null })
                  }}
                >
                  <AddIcon />
                </Fab>
              </div> )}
              
              {!currentRow && renderP && addedRows.map((value: any, idx: number) => (<>
                <FormControl>
                  <Autocomplete
                    value={formik.values.applicant[idx + 1]}
                    options={
                      [
                        { id: '', firstName: '', lastName: '' },
                        ...applicantsAll.map((user: any) => ({ id: user?._id, firstName: user?.firstName, lastName: user?.lastName }))
                      ]
                    }
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    onChange={(event: any, newValue: any | null) => {
                      if (null || !newValue) {
                        formik.setFieldValue(`applicant${idx + 1}`, '');
                      }
                      else {
                        formik.setFieldValue(`applicant${idx + 1}`, newValue.id)
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Applicant" />
                    )}
                  /></FormControl>
                <IconButton
                  sx={{
                    bgcolor: 'red',
                    color: 'white',
                    float: 'right',
                    height: '35px',
                    width: '35px',
                    marginRight: '8px',
                    marginTop: '10px'
                  }}
                  aria-label="Remove Item"
                  onClick={() => {
                    removeRows(idx)
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton></>
              ))}
             
            </Box>
          </Stack>
          <Box
            sx={{
              padding: '20px 0px 10px 0px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button onClick={closeMe} variant="contained" sx={{ bgcolor: '#a3a3ab' }} type="button">
              Cancel
            </Button>
            <Button variant="contained" sx={{ bgcolor: 'var(--primary-color)', float: 'right' }} type="submit">
              Submit
            </Button>

          </Box>
        </form>
      </Box>
    </>
  );
};

export default ActivtyModalContent;
