import {
  FETCH_DOCUMENTS_REQUEST,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_FAILURE,
  UPLOAD_DOCUMENT_REQUEST,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAILURE,
  DELETE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_FAILURE,
  FETCH_DOC_PREVIEW_REQUEST,
  FETCH_DOC_PREVIEW_SUCCESS,
  FETCH_DOC_PREVIEW_FAILURE,
  RENAME_DOC_REQUEST,
  RENAME_DOC_SUCCESS,
  RENAME_DOC_FAILURE,
  SAVE_DOC_REQUEST,
  SAVE_DOC_SUCCESS,
  SAVE_DOC_FAILURE,
  SELECTED_NODE,
  OBJECT_SELECTED_NODE,
  UPLOAD_DOCUMENT_PROGRESS,
  ZERO_LEVEL_DATA,
  ZERO_LEVEL_DATA_REQUEST,
  ZERO_LEVEL_DATA_SUCCESS,
  ZERO_LEVEL_DATA_FAILURE,
  FETCH_FILE_SECTIONS_REQUEST,
  FETCH_FILE_SECTIONS_SUCCESS,
  FETCH_FILE_SECTIONS_FAILURE
} from '../actions/actionTypes';

const initialState = {
  fetchDocumentsLoading: false,
  uploadDocumentLoading: false,
  deleteDocumentLoading: false,
  fetchDocPreviewLoading: false,
  renameDocLoading: false,
  saveDocLoading: false,
  documents: [],
  selectedNode: null,
  selectedItemParentId: null,
  previewData: null,
  folderList: [],
  error: null,
  uploadProgress: 0,
  zeroLevelDataLoading: false,
  parentSelected: null,
  fetchFileSectionsLoading: false,
  fileSections:[]
};

const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    // Document Fetch
    case FETCH_DOCUMENTS_REQUEST:
      return { ...state, fetchDocumentsLoading: true, error: null };
    case FETCH_DOCUMENTS_SUCCESS:
      return { ...state, fetchDocumentsLoading: false, documents: action.payload };
    case FETCH_DOCUMENTS_FAILURE:
      return { ...state, fetchDocumentsLoading: false, error: action.error };

    // Document Fetch
    case FETCH_FILE_SECTIONS_REQUEST:
      return { ...state, fetchFileSectionsLoading: true, error: null };
    case FETCH_FILE_SECTIONS_SUCCESS:
      return { ...state, fetchFileSectionsLoading: false, fileSections: action.payload };
    case FETCH_FILE_SECTIONS_FAILURE:
      return { ...state, fetchFileSectionsLoading: false, error: action.error };


    // Document Upload
    case UPLOAD_DOCUMENT_REQUEST:
      return { ...state, uploadDocumentLoading: true, uploadProgress: 0, error: null };
    case UPLOAD_DOCUMENT_PROGRESS:
      return { ...state, uploadProgress: action.payload };
    case UPLOAD_DOCUMENT_SUCCESS:
      return { ...state, uploadDocumentLoading: false, uploadProgress: 0 };
    case UPLOAD_DOCUMENT_FAILURE:
      return { ...state, uploadDocumentLoading: false, uploadProgress: 0, error: action.error };

    // Document Delete
    case DELETE_DOCUMENT_REQUEST:
      return { ...state, deleteDocumentLoading: true, error: null };
    case DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        deleteDocumentLoading: false,
        documents: state.documents.filter(doc => doc._id !== action.payload),
      };
    case DELETE_DOCUMENT_FAILURE:
      return { ...state, deleteDocumentLoading: false, error: action.error };

    // Document Preview
    case FETCH_DOC_PREVIEW_REQUEST:
      return { ...state, fetchDocPreviewLoading: true, error: null };
    case FETCH_DOC_PREVIEW_SUCCESS:
      return { ...state, fetchDocPreviewLoading: false, previewData: action.payload };
    case FETCH_DOC_PREVIEW_FAILURE:
      return { ...state, fetchDocPreviewLoading: false, error: action.error };

    // Document Rename
    case RENAME_DOC_REQUEST:
      return { ...state, renameDocLoading: true, error: null };
    case RENAME_DOC_SUCCESS:
      return {
        ...state,
        renameDocLoading: false,
        documents: state.documents.map(doc =>
          doc._id === action.payload._id ? action.payload : doc
        ),
      };
    case RENAME_DOC_FAILURE:
      return { ...state, renameDocLoading: false, error: action.error };

    // Document Save
    case SAVE_DOC_REQUEST:
      return { ...state, saveDocLoading: true, error: null };
    case SAVE_DOC_SUCCESS:
      return {
        ...state,
        saveDocLoading: false,
        documents: Array.isArray(state.documents) ? [...state.documents, action.payload] : [action.payload],
      };
    case SAVE_DOC_FAILURE:
      return { ...state, saveDocLoading: false, error: action.error };

    case SELECTED_NODE:
      return {
        ...state,
        selectedNode: action.payload,
      };

    case ZERO_LEVEL_DATA_REQUEST:
      return { ...state, zeroLevelDataLoading: true, error: null };
    case ZERO_LEVEL_DATA_SUCCESS:
      return { ...state, zeroLevelDataLoading: false, parentSelected: action.payload };
    case ZERO_LEVEL_DATA_FAILURE:
      return { ...state, zeroLevelDataLoading: false, error: action.error };


    case OBJECT_SELECTED_NODE:
      return {
        ...state,
        folderList: action.payload,
      };

    default:
      return state;
  }
};

export default documentReducer;
