import { CardHeader, Layout } from 'components'
import React, { useEffect, useState } from 'react'
import API from 'services/AxiosConfig'
import { numberToMonth } from 'lib'
import BarChart from 'components/Chart/BarChart'
import ProspectsWonLostBarChart from 'views/Reporting/Prospects/ProspectsWonLostBarChart'
import ProspectsTotalNewBarChart from 'views/Reporting/Prospects/ProspectsTotalNewBarChart'
import ProspectsNewAndWonChart from 'views/Reporting/Prospects/ProspectsNewAndWonChart'
import ProspectsWinPercentage from 'views/Reporting/Prospects/ProspectsWinPercentage'
import { FilterHeader } from 'views'
import { useAuth } from 'hooks'
import ReferralPartnersProspectTable from 'views/Reporting/Prospects/ReferralPartnersProspectTable'
import ProspectsByStageTable from 'views/Reporting/Prospects/ProspectsByStageTable'
import ProspectsBySourceTable from 'views/Reporting/Prospects/ProspectsBySourceTable'
import ProspectsByReferralPartnerTable from 'views/Reporting/Prospects/ProspectsByReferralPartnerTable'
import ProspectsLostReasonTable from 'views/Reporting/Prospects/ProspectsLostReasonTable'
import ProspectsByReferralPartnerTypeTable from 'views/Reporting/Prospects/ProspectsByReferralPartnerTypeTable'


function ProspectsReporting(props: any) {

   const { user } = useAuth();
    const [filter, setFilter] = useState({
        location: { id: user ? user?.location._id :'0', value: user ? user?.location?.location : 'All' },
        timeFrame:{id: 'last6Months', value: 'Last 6 Months' }
    })

    return (
        <Layout>
            <div style={{ marginTop: '10%', marginBottom:'2%'}}>
                <FilterHeader type='prospectsReporting' setFilter={setFilter} filter={filter} />
            </div>
            <div style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridGap: '30px',
                placeItems: 'center',
                paddingLeft: '130px',
                paddingRight: '100px'
            }}>
                <ProspectsWonLostBarChart filter = {filter} />
                <ProspectsNewAndWonChart filter = {filter} />
                <ProspectsTotalNewBarChart filter = {filter}/>
                <ProspectsWinPercentage filter = {filter}/>

                {/* <ProspectsTotalNewBarChart filter = {filter}/>
                <ProspectsNewAndWonChart filter = {filter} />
                <ProspectsWonLostBarChart filter = {filter} />
                <ProspectsWinPercentage filter = {filter}/> */}
            </div>
            <div style={{
                display: 'grid',
                gridTemplateColumns: '1fr',
                gridGap: '20px',
                placeItems: 'center',
                paddingLeft: '130px',
                paddingRight: '100px'
            }}> 
                <ReferralPartnersProspectTable filter = {filter}/>
                <ProspectsBySourceTable filter = {filter}/>
                <ProspectsByReferralPartnerTable filter = {filter} />
                <ProspectsByReferralPartnerTypeTable filter = {filter} />
                <ProspectsLostReasonTable filter = {filter}/>
            </div>
        </Layout>
    )
        }

export default ProspectsReporting


