import React, { useState, useEffect } from 'react';
import API from 'services/AxiosConfig';
import {
    TableContainer,
    Table as MuiTable,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody,
    Button
} from '@mui/material';
import * as XLSX from 'xlsx';
import { NoData, Spinner } from 'components';

const data = {
    headers: ['Jan-23', 'Feb-23', 'Mar-23', 'Apr-23', 'May-23', 'Jun-23'],
    section1: [
        {
            label: 'Beginning Prospects',
            values: []
        },
        {
            label: 'New Prospects',
            values: []
        }
    ],
    section2: [
        {
            label: 'Prospects Won',
            values: []
        },
        {
            label: 'Prospects Lost',
            values: []
        }
    ]
};
interface Props {filter:any }
interface RowData {
    label: string;
    values: Array<number | string>;
}

function ClientActivityTable(props: Props) {
    const {filter} = props
    const [tableData, setTableData] = useState<any>(data)
    const headers = ['Client Partner Activity', ...tableData?.headers || '', 'Total'];
    const [loading,setLoading] =useState<boolean>(false)
    function calculateSum(section: any) {
        return section.reduce((totals: any, row: any) => {
            row?.values?.forEach((value: any, i: any) => {
                totals[i] = (totals[i] || 0) + value;
            });
            return totals;
        }, []);
    }
 


    useEffect(() => {
        setLoading(true)
        const currentDate1 = new Date();
        let year = currentDate1.getFullYear();
        let month = currentDate1.getMonth() + 1;
        let day = currentDate1.getDate();

        let queryObj: any = {
            startYear: year,
            startMonth: month,
            startDay: day,
            location: filter?.location?.id || 'All'  // Assuming location works fine
        };
        const startOfWeek = new Date(currentDate1);
        startOfWeek.setDate(currentDate1.getDate() - day);
        startOfWeek.setHours(0, 0, 0, 0);


        const now = new Date();
        const dayOfWeek = now.getDay(); // Day of week (0-6, 0 is Sunday)
        const currentDate = now.getDate(); // Day of month (1-31)
        const currentMonth = now.getMonth(); // Month (0-11, 0 is January)
        const currentYear = now.getFullYear(); // Full year (YYYY)
        const getFirstDayOfWeek = () => {
            return new Date(now.setDate(now.getDate() - dayOfWeek));
        };

        const daysInMonth = (year: any, month: any) => {
            return new Date(year, month + 1, 0).getDate();
        };

        const filterLogic: any = {
            'thisWeek': () => {
                queryObj.timeUnit = 'day';
                queryObj.span = 7;
                queryObj.startDay = getFirstDayOfWeek().getDate(); // Assuming week starts on Sunday
                queryObj.startMonth = currentMonth

            },
            'thisMonth': () => {
                queryObj.timeUnit = 'day';
                queryObj.span = daysInMonth(currentYear, currentMonth - 1);
                queryObj.startDay = 1; // Start from the first day of the current month
                queryObj.startMonth = currentMonth 

            },
            'yearToDate': () => {
                queryObj.timeUnit = 'month';
                const currentDate = new Date(); // Get the current date
                const currentYear = currentDate.getFullYear(); // Extract the current year
                const currentMonth = currentDate.getMonth();
                queryObj.span = currentMonth + 1;
                queryObj.startMonth = 0;
            },
            'lastWeek': () => {
                queryObj.timeUnit = 'day';
                queryObj.span = 7;
                queryObj.startDay = getFirstDayOfWeek().getDate() - 7; // Assuming week starts on Sunday
                queryObj.startMonth = currentMonth 
            },
            'lastMonth': () => {
                queryObj.timeUnit = 'day';
                const lastMonth = currentMonth - 1;
                queryObj.span = daysInMonth(currentYear, lastMonth);
                queryObj.startDay = 1; // Start from the first day of the last month
                queryObj.startMonth = lastMonth;
            },
            'last3Months': () => {
                queryObj.timeUnit = 'month';
                queryObj.span = 3;
                queryObj.startMonth = month - 3;
            },
            'last6Months': () => {
                queryObj.timeUnit = 'month';
                queryObj.span = 6;
                queryObj.startMonth = month - 6;
            },
            'last12Months': () => {
                queryObj.timeUnit = 'month';
                queryObj.span = 12;
                queryObj.startYear = year - 1
            }
        };

        // Use the selected filter to update the query object
        const selectedFilter = filter?.timeFrame?.id;
        if (selectedFilter && filterLogic[selectedFilter]) {
          filterLogic[selectedFilter]();
        }   
        API.post('/client/monthly-beginning-clients', queryObj)
            .then(({data}:any) => {
                setTableData(data?.data)
                setLoading(false)
            })
            .catch(err =>{
                console.log(err)
                setLoading(false)
            })
    }, [filter]);


    const setColumnWidths = (ws: any, numOfCols: number, defaultWidth: number) => {
        const colWidths = Array.from({ length: numOfCols }, () => ({ wpx: defaultWidth }));
        ws['!cols'] = colWidths;
    };

    const exportToExcel = () => {
        setLoading(true)
        console.log(tableData.section,headers)
       
            const copy:any = tableData.section.slice()
            const totalRow = {
                label: 'Total',
                values: tableData?.headers?.map((_: any, i: any) => {
                    const beginningClients = copy?.find((row: any) => row.label === 'Beginning Clients')?.values[i] || 0;
                    const newClients = copy?.find((row: any) => row.label === 'New Clients')?.values[i] || 0;
                    const lostClients = copy?.find((row: any) => row.label === 'Lost Clients')?.values[i] || 0;
                    return (beginningClients + newClients) - lostClients;
                })
            };
            copy.push(totalRow)
            const ws = XLSX.utils.json_to_sheet(copy.map((row:any) => {
              let obj: { [key: string]: number | string } = {};
              // Use the first value of headers for the label
              obj[headers[0]] = row.label;
              // Map values to the other headers
              row.values.forEach((value:any, index:number) => {
                obj[headers[index + 1]] = value; // '+1' to skip the first header
              });
              return obj;
            }));
            setColumnWidths(ws, headers.length, 75);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            XLSX.writeFile(wb, `Client_Activity.xlsx` || "data.xlsx");
            setLoading(false)
    }


    function renderSumRow(section: any) {
        const totalRow = {
            label: 'Total',
            values: tableData?.headers?.map((_: any, i: any) => {
                const beginningClients = section?.find((row: any) => row.label === 'Beginning Clients')?.values[i] || 0;
                const newClients = section?.find((row: any) => row.label === 'New Clients')?.values[i] || 0;
                const lostClients = section?.find((row: any) => row.label === 'Lost Clients')?.values[i] || 0;
                return (beginningClients + newClients) - lostClients;
            })
        };
    
        return (
            <TableRow key={totalRow.label}>
                {totalRow.values.map((value: any, j: any) =>
                    <TableCell key={j} align="right" style={{ fontWeight: 'bold' }}>{value}</TableCell>
                )}
            </TableRow>
        );
    }


  
    function renderRow(row: any, i: any, isBold = false) {
        return (
            <TableRow key={i}>
                {row.values.map((value: any, j: any) =>
                    <TableCell key={j} align="right" style={{ fontWeight: isBold ? 'bold' : 'normal' }}>{row.label === 'Win Percentage' ? value.toFixed(2) + '%' : value}</TableCell>
                )}
               
            </TableRow>
        );
    }
    function renderFirstColumn(row: any, i: any, isBold = false) {
        return (
            <TableRow key={i} style = {{width:'50px'}}>
                <TableCell component="th" scope="row" style={{ fontWeight: isBold ? 'bold' : 'normal', }}>{row.label}</TableCell>
            </TableRow>
        );
    }

    return (
        <div style={{ width: '100%', maxWidth: '1600px', marginBottom:'4%' }}> 
         <Button variant="contained" color="primary" style={{ float: 'right', margin: 0, marginBottom: '5px' }} onClick={exportToExcel} >
            Download Data
        </Button>
        <div style={{ display: 'flex', flexDirection: 'row',width: '100%', maxWidth: '1700px' }}>
        <TableContainer component={Paper} style = {{width:'375px'}}>
                    <MuiTable>
                        <TableHead>
                            <TableRow style={{ minWidth: '200px' }}>

                                <TableCell style={{ backgroundColor: '#2f374e', color: 'white' }} align={'left'} >Client Activity</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                // Render only the Spinner when loading
                                <TableRow>
                                    <TableCell
                                        colSpan={Math.min(headers.length * 2, 18)}
                                        style={{ textAlign: 'center', padding: '20px' }}
                                    >
                                        <Spinner />
                                    </TableCell>
                                </TableRow>
                            ) : tableData?.section?.length === 0 ? (
                                // Render 'No Data' component or message if there are no rows and not loading
                                <TableRow style={{ width: '100%' }}>
                                    <TableCell
                                        colSpan={Math.min(headers.length * 2, 18)}
                                        style={{ textAlign: 'center', padding: '20px' }}
                                    >
                                        <NoData />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <>
                                    {tableData?.section?.map((row: any, i: any) => renderFirstColumn(row, i))}
                                    <TableRow sx={{ borderTop: '2px solid #2f374e' }} />
                                    <TableRow >
                                        <TableCell component="th" scope="row" style={{ fontWeight: 'bold', }}>Ending Clients</TableCell>
                                    </TableRow>
                                </>
                            )
                            }
                        </TableBody>
                    </MuiTable>
                </TableContainer>
            <TableContainer component={Paper}>
                <MuiTable>
                    <TableHead>
                        <TableRow>
                            {headers.slice(1,-1).map((header, i) =>
                                <TableCell style={{ backgroundColor: '#2f374e', color: 'white',minWidth:'75px' }} key={i} align={ 'right' } >{header}</TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        
                    {
    loading ? (
        // Render only the Spinner when loading
        <TableRow>
            <TableCell
                colSpan={Math.min(headers.length * 2, 18)}
                style={{ textAlign: 'center', padding: '20px' }}
            >
                <Spinner />
            </TableCell>
        </TableRow>
    ) : tableData?.section?.length === 0 ? (
        // Render 'No Data' component or message if there are no rows and not loading
        <TableRow style={{ width: '100%' }}>
            <TableCell
                colSpan={Math.min(headers.length * 2, 18)}
                style={{ textAlign: 'center', padding: '20px' }}
            >
                <NoData />
            </TableCell>
        </TableRow>
    ) : (
        <>
            {tableData?.section?.map((row:any, i:any) => renderRow(row, i))}
            <TableRow sx={{ borderTop: '2px solid #2f374e' }} />
            {renderSumRow(tableData.section)}
        </>
    )
}

                    </TableBody>
                </MuiTable>
            </TableContainer>
        </div>
        </div>
    );
}

export default ClientActivityTable

