import { Layout, Spinner } from 'components'
import React, { useEffect, useState } from 'react'
import API from 'services/AxiosConfig'
import { numberToMonth } from 'lib'
import BarChart from 'components/Chart/BarChart'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { formatQueryObj } from '../common'
interface ProspectData {
    totalProspects: Array<{ count: number, year: number, month: number }>,
    newProspects: Array<{ count: number, year: number, month: number }>
}

interface ChartData {
    labels: string[],
    datasets: Array<{
        label: string,
        data: number[],
        backgroundColor: string,
        borderColor: string,
        borderWidth: number
    }>
}


function ProspectsTotalNewBarChart(props: any) {
    const { filter } = props
    const [chartData, setChartData] = useState<any>(null)
    const [max, setMax] = useState<any>(null)
    const [loading,setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        const queryObj = formatQueryObj(filter)
         API.post('prospects/reporting/oldandnew', queryObj)
            .then(data => {
                formatChartData(data.data);
                setLoading(false)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false)
            });
    }, [filter]);



    const formatChartData = (rawData: any) => {
        const { headers, rows } = rawData.data;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const filteredHeaders = headers.filter((header: any) => {
            const headerDate = new Date(header);
            return headerDate <= today;
        });
        let newCounts: number[] = [];
        let oldCounts: number[] = [];
    
        rows.forEach((row: any) => {
            const { label, values } = row;
    
            if (label === 'New') {
                newCounts = values.map((v: any) => v.countForStage);
            }
    
            if (label === 'Old') {
                oldCounts = values.map((v: any) => v.countForStage);
            }
        });
        const lastIndex = filteredHeaders.length;

        // Filter data arrays to match the new headers array
        const filteredNewCounts = newCounts.slice(0, lastIndex);
        const filteredOldCounts = oldCounts.slice(0, lastIndex);
    
        const chartData = {
            labels: headers,
            datasets: [
                {
                    label: 'New',
                    data: filteredNewCounts,
                    backgroundColor: '#7cb5ec',
                    // ... (existing datalabels code)
                },
                {
                    label: 'Existing',
                    data: filteredOldCounts,
                    backgroundColor: '#434348',
                    // ... (existing datalabels code)
                }
            ]
        };
    
        const allCounts = newCounts.map((newCount,i) => {
            return newCount + oldCounts[i]
        });
        
        const max = Math.max(...allCounts);
    
        setMax(max);
        setChartData(chartData);
    };
   

    const chartOptions:any = {
        maintainAspectRatio: false,
        layout: {
            padding: 25
        },
        scales: {

            x: {
                grid: {
                    display: false,
                },
                stacked: true,

            },
            y: {
                beginAtZero: true,
                ticks: {
                    maxTicksLimit: 10
                },
                max: max,
                stacked: true,

            }
        },
        plugins: {
            legend: {
                position: 'bottom',
            },
            datalabels:{
                color:'white',
                display: function(context:any) {
                    return context.dataset.data[context.dataIndex] > 1; // or >= 1 or ...
                 }
            }
        },
    }
  
    return (

        <div style={{ height: '350px', width: '700px', }}>
            <h3 >Total and Existing Prospects</h3>
            {chartData && !loading ? <BarChart chartData={chartData} options={chartOptions} renderLabels /> :<div><Spinner/></div>}
        </div>
    )
}

export default ProspectsTotalNewBarChart


