type InputData = {
    count: number;
    year: number;
    month: number;
    stage: string;
}[];

export const formatChartData = (input: InputData) => {
    const newProspects: number[] = [];
    const wonProspects: number[] = [];
    const labels: string[] = [];

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth(); // getMonth() is zero-indexed.

    // Initialize arrays for last 6 months
    for (let i = 5; i >= 0; i--) {
        let monthIndex = currentMonth - i;
        let year = currentYear;
        if (monthIndex < 0) {
            monthIndex += 12; // handle rollover
            year--;
        }
        newProspects[5 - i] = 0;
        wonProspects[5 - i] = 0;
        labels[5 - i] = `${monthNames[monthIndex]} ${year}`;
    }

    for (const item of input) {
        const monthDifference = (currentYear - item.year) * 12 + currentMonth - (item.month - 1);
        if (monthDifference >= 0 && monthDifference < 6) {
            const index = 5 - monthDifference; // reverse the index
            if (item.stage === 'new') {
                newProspects[index] += item.count;
            } else if (item.stage === 'won') {
                wonProspects[index] += item.count;
            }
        }
    }

    return {
        labels,
        datasets: [
            {
                label: 'Prospects Won',
                data: wonProspects,
                type: 'line', // specify type here
                backgroundColor: '#434348',
                borderColor: '#434348',
                fill: false, // to prevent filling under the line chart
                datalabels: {
                    color: 'black',
                    display: function (context: any) {
                        return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or ...
                    },
                },
            },
            {
                label: 'New Prospects',
                data: newProspects,
                type: 'bar', // specify type here
                backgroundColor: '#7cb5ec',
                datalabels: {
                    color: 'black',
                    anchor: 'end',
                    align: 'top',
                    display: function (context: any) {
                        return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or ...
                    },
                    formatter: function (value: any, context: any) {
                        return value
                    }
                },
            },
        ],
    }
}

export const formatQueryObj = (filter: any) => {
    const currentDate1 = new Date();
    let year = currentDate1.getFullYear();
    let month = currentDate1.getMonth() + 1;
    let day = currentDate1.getDate();

    let queryObj: any = {
        startYear: year,
        startMonth: month,
        startDay: day,
        location: filter?.location?.id || 'All'  // Assuming location works fine,
    };
    if (filter?.company?.value) {
        queryObj.company = filter.company.id
    }
    if (filter?.referralPartner?.value) {
        queryObj.referralPartner = filter.referralPartner.id
    }
    if (filter?.companyType?.value) {
        queryObj.companyType = filter.companyType.id
    }
    if (filter.flag) {
        queryObj.flag = filter.flag
    }
    if (filter.excludeNoActivity) {
        queryObj.excludeNoActivity = filter.excludeNoActivity
    }
    const startOfWeek = new Date(currentDate1);
    startOfWeek.setDate(currentDate1.getDate() - day);
    startOfWeek.setHours(0, 0, 0, 0);


    const now = new Date();
    const dayOfWeek = now.getDay(); // Day of week (0-6, 0 is Sunday)
    const currentDate = now.getDate(); // Day of month (1-31)
    const currentMonth = now.getMonth(); // Month (0-11, 0 is January)
    const currentYear = now.getFullYear(); // Full year (YYYY)
    const getFirstDayOfWeek = () => {
        return new Date(now.setDate(now.getDate() - dayOfWeek));
    };

    const daysInMonth = (year: any, month: any) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const filterLogic: any = {
        'thisWeek': () => {
            queryObj.timeUnit = 'day';
            queryObj.span = 7;
            queryObj.startDay = getFirstDayOfWeek().getDate(); // Assuming week starts on Sunday
            queryObj.startMonth = currentMonth
        },
        'thisMonth': () => {
            queryObj.timeUnit = 'day';
            queryObj.span = daysInMonth(currentYear, currentMonth);
            queryObj.startDay = 1; // Start from the first day of the current month
            queryObj.startMonth = currentMonth

        },
        'yearToDate': () => {
            queryObj.timeUnit = 'month';
            const currentDate = new Date(); // Get the current date
            const currentYear = currentDate.getFullYear(); // Extract the current year
            const currentMonth = currentDate.getMonth();
            queryObj.span = currentMonth + 1;
            queryObj.startMonth = 0;
        },
        'lastWeek': () => {
            queryObj.timeUnit = 'day';
            queryObj.span = 7;
            queryObj.startDay = getFirstDayOfWeek().getDate() - 7; // Assuming week starts on Sunday
            queryObj.startMonth = currentMonth
        },
        'lastMonth': () => {
            queryObj.timeUnit = 'day';
            const lastMonth = currentMonth - 1;
            queryObj.span = daysInMonth(currentYear, lastMonth);
            queryObj.startDay = 1; // Start from the first day of the last month
            queryObj.startMonth = lastMonth;
        },
        'last3Months': () => {
            queryObj.timeUnit = 'month';
            queryObj.span = 3;
            queryObj.startMonth = month - 3;
        },
        'last6Months': () => {
            queryObj.timeUnit = 'month';
            queryObj.span = 6;
            queryObj.startMonth = month - 6;
        },
        'last12Months': () => {
            queryObj.timeUnit = 'month';
            queryObj.span = 12;
            queryObj.startYear = year - 1
        }
    };
    const selectedFilter = filter?.timeFrame?.id;
    if (selectedFilter && filterLogic[selectedFilter]) {
        filterLogic[selectedFilter]();
    }

    return queryObj
}

export const generateData = (data2: any, dtype = "") => {
    let data1 = data2.data;
    if (dtype === "delta" && data1?.rows.length > 10) {
        data1.rows = data1.rows.slice(0, 10);
    }
    const chartData: any[] = [];
    let headers = data2.data?.headers.filter((item: any) => item.subHeaders !== undefined && item.label !== "Total");
    //headers = headers?.map((a: any) => a.label);
    //headers = headers.map((a: any) => a);


    headers = headers.map((header: any) => {
        if (typeof header === 'object' && header !== null && 'label' in header) {
            return header.label;
        }
        else if (typeof header === 'object' && header !== null && 'subHeaders' in header) {
            return header.subHeaders[0];
        }
        return header;
    });

    const maxLength = Math.max(...data1?.rows.map((item: { values: string | any[]; }) => item.values.length));
    const sums = new Array(maxLength).fill(0);
    let activity: any[] = [];
    let refferal: any[] = [];
    const newHeader: any[] = [];
    data1?.rows.forEach((item: { label: any; values: any[]; }) => {
        if (item.label) {
            newHeader.push(item.label);
        }
        item.values.forEach((value, index) => {
            const num = typeof value === 'string' ? parseFloat(value) : value;
            sums[index] += num;

            if (dtype === "delta") {
                if (!chartData[index]) {
                    chartData[index] = "";
                }
                if (chartData[index]) {
                    chartData[index] += ",";
                }
                chartData[index] += num;
            }
        });
    });
    if (dtype === "delta") {
        chartData.forEach((element, index) => {
            if (index === 0 || index === 1 || index === 2) {
                activity.push(element);
            }
            else {
                refferal.push(element);
            }
        });

    }
    else {
        if (dtype !== "industry_category") {
            sums.forEach((element, index) => {
                if (index % 2 === 0) {
                    activity.push(element);
                }
                else {
                    refferal.push(element);
                }

            });
        }
        else if (dtype === "industry_category") {
            sums.forEach((element, index) => {
                if (index === 0 || index === 1 || index === 2) {
                    activity.push(element);
                }
                else {
                    refferal.push(element);
                }
            });
        }
    }


    return { headers: headers, activity: activity, refferal: refferal, all: sums, actirefertypeheader: newHeader, api: data2 }
};

export const getParentStageId = (data: any, cardItemId: string) => {
    // Iterate over each progress item
    console.log('cardItemId----' + cardItemId);
    console.log( data);
    for (const progressItem of data.progress) {
        // Iterate over each cardItem within the current progress item
        for (const cardItem of progressItem.cardItems) {
            // Check if the cardItem's _id matches the provided cardItemId
            if (cardItem._id === cardItemId) {
                // Return the parent cardName._id
                console.log('progressItem----' + progressItem.cardName._id);
                return progressItem.cardName._id;
            }
        }
    }
    // Return null if no match is found
    return cardItemId;
}