import React, { useState, useEffect } from 'react';
import ReportingTable from '../ReportingTable';
import API from 'services/AxiosConfig';
import { formatQueryObj } from '../common';

const ApplicantsLostReasonTable = ({ filter }: any) => {
    const [data, setData] = useState({
        headers: [],
        rows: []
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
      const queryObj = formatQueryObj(filter)
        API.post('/applicants/reporting/monthly-prospects-lost-client', queryObj)
            .then(({ data }: any) => {
                setData(data?.data)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }, [filter]);

    return <ReportingTable loading={loading} setLoading={setLoading} headers={data?.headers} rows={data?.rows} sumRowLabel="Total Lost Applicants" sheetName='Lost Applicants by Reason' />

};

export default ApplicantsLostReasonTable;


