import { Layout, Spinner } from 'components'
import React, { useEffect, useState } from 'react'
import API from 'services/AxiosConfig'
import { numberToMonth } from 'lib'
import BarChart from 'components/Chart/BarChart'
import LineChart from 'components/Chart/LineChart'
import ChartDataLabels from 'chartjs-plugin-datalabels';



function CarePartnersTotalChart(props: any) {
    const { filter } = props
    const [chartData, setChartData] = useState<any>(null)
    const [max, setMax] = useState<any>(null)
    const [loading,setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        const currentDate1 = new Date();
        let year = currentDate1.getFullYear();
        let month = currentDate1.getMonth() + 1;
        let day = currentDate1.getDate();

        let queryObj: any = {
            startYear: year,
            startMonth: month,
            startDay: day,
            location: filter?.location?.id || 'All'  // Assuming location works fine
        };
        const startOfWeek = new Date(currentDate1);
        startOfWeek.setDate(currentDate1.getDate() - day);
        startOfWeek.setHours(0, 0, 0, 0);


        const now = new Date();
        const dayOfWeek = now.getDay(); // Day of week (0-6, 0 is Sunday)
        const currentDate = now.getDate(); // Day of month (1-31)
        const currentMonth = now.getMonth(); // Month (0-11, 0 is January)
        const currentYear = now.getFullYear(); // Full year (YYYY)
        const getFirstDayOfWeek = () => {
            return new Date(now.setDate(now.getDate() - dayOfWeek));
        };

        const daysInMonth = (year: any, month: any) => {
            return new Date(year, month + 1, 0).getDate();
        };

        const filterLogic: any = {
            'thisWeek': () => {
                queryObj.timeUnit = 'day';
                queryObj.span = 7;
                queryObj.startDay = getFirstDayOfWeek().getDate(); // Assuming week starts on Sunday
                queryObj.startMonth = currentMonth

            },
            'thisMonth': () => {
                queryObj.timeUnit = 'day';
                queryObj.span = daysInMonth(currentYear, currentMonth - 1);
                queryObj.startDay = 1; // Start from the first day of the current month
                queryObj.startMonth = currentMonth 

            },
            'yearToDate': () => {
                queryObj.timeUnit = 'month';
                const currentDate = new Date(); // Get the current date
                const currentYear = currentDate.getFullYear(); // Extract the current year
                const currentMonth = currentDate.getMonth();
                queryObj.span = currentMonth + 1;
                queryObj.startMonth = 0;
            },
            'lastWeek': () => {
                queryObj.timeUnit = 'day';
                queryObj.span = 7;
                queryObj.startDay = getFirstDayOfWeek().getDate() - 7; // Assuming week starts on Sunday
                queryObj.startMonth = currentMonth 
            },
            'lastMonth': () => {
                queryObj.timeUnit = 'day';
                const lastMonth = currentMonth - 1;
                queryObj.span = daysInMonth(currentYear, lastMonth);
                queryObj.startDay = 1; // Start from the first day of the last month
                queryObj.startMonth = lastMonth;
            },
            'last3Months': () => {
                queryObj.timeUnit = 'month';
                queryObj.span = 3;
                queryObj.startMonth = month - 3;
            },
            'last6Months': () => {
                queryObj.timeUnit = 'month';
                queryObj.span = 6;
                queryObj.startMonth = month - 6;
            },
            'last12Months': () => {
                queryObj.timeUnit = 'month';
                queryObj.span = 12;
                queryObj.startYear = year - 1
            }
        };

        // Use the selected filter to update the query object
        const selectedFilter = filter?.timeFrame?.id;
        if (selectedFilter && filterLogic[selectedFilter]) {
            filterLogic[selectedFilter]();
        }
        API.post('care-partner/reporting/total-carepartners', queryObj)
            .then(data => {
                formatChartData(data.data);
                setLoading(false)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false)
            });
    }, [filter]);

    const formatChartData = (rawData: any) => {
        // Extract headers and rows from the raw data
        const { headers, rows } = rawData.data;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const filteredHeaders = headers.filter((header: any) => {
            const headerDate = new Date(header);
            return headerDate <= today;
        });



        // Initialize arrays to hold the counts of 'won' and 'lost' prospects for each period
        let wonCounts: number[] = [];
        let lostCounts: number[] = [];
        let percentages: number[] = []

        // Loop through each row to populate the 'wonCounts' and 'lostCounts' arrays
        rows.forEach((row: any) => {
            const { label, values } = row;

            if (label === 'total') {
                wonCounts = values.map((v: any) => v.countForStage);
            }



            if (wonCounts.length) {
                wonCounts.forEach((item, i) => {
                    let percentage = (item) || 0
                    percentages.push(Math.trunc(percentage))
                })
            }


        });

        const lastIndex = filteredHeaders.length;

        // Filter data arrays to match the new headers array
        const filteredWonCounts = wonCounts.slice(0, lastIndex);

        // Create the chart data
        const chartData = {
            labels: headers,  // remove the first and last element, assuming they are not relevant for the chart
            datasets: [
                {
                    data: filteredWonCounts,
                    borderColor: '#3366CC', // use borderColor for line charts
                    fill: false // do not fill under the line
                }
            ]
        };

        // If you want to find the maximum value among both won and lost counts
        const allCounts = [...wonCounts, ...lostCounts];
        const max = Math.max(...allCounts);

        // Use the setMax and setChartData methods if you have them to update the state
        setMax(max);
        setChartData(chartData);

        // return { chartData, max };
    };

    const chartOptions = {
        maintainAspectRatio: false,
        layout: {
            padding: 25
        },
        scales: {
            x: {
                grid: {
                    display: false
                },

            },
            y: {
                beginAtZero: true,
                ticks: {
                    maxTicksLimit: 10,
                },
                scaleLabel: {
                    display: true,
                },
                suggestedMax: max + 2,
            }
        },
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                display: true,
                color: 'black',
                align: 'bottom',
                font: {
                    size: 10 // or a smaller size depending on your need
                },

                formatter: function (value: any, context: any) {

                    return value.toFixed(0); // format value to two decimal places and append '%'

                }
            }
        },
    }
    return (

        <div style={{ height: '350px', width: '700px', }}>
            <h3 >Total Care Partners</h3>
            {chartData && !loading ? <LineChart chartData={chartData} option={chartOptions} /> : <div><Spinner/></div>}
        </div>
    )
}

export default CarePartnersTotalChart
