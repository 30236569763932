import React from 'react'
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ChartDataLabels);
interface Props {chartData:any,option:any }

function LineChart(props: Props) {
    const {chartData = [],option={}} = props

    return (
        <Line
            options={option}
            data={chartData}
            {...props}
       
        />
    )
}

export default LineChart
