

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Card, CardContent, Typography, Grid, Button, IconButton } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import API from 'services/AxiosConfig';
import ChecklistComponent from './Checklist';
import { useAuth } from 'hooks';
import { toast } from 'react-toastify'
import { Spinner } from 'components';

const useStyles = makeStyles((theme) => ({
    checklist: {
        minHeight: '350px',
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    columnsList: {
        minHeight: '300px',
        padding: '20px',
        border: '1px solid #ddd',
        borderRadius: '10px',
        backgroundColor: '#f9f9f9',
        maxHeight: '400px',
        overflowY: 'auto',
    },
    configTitle: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%', // Set a specific max-width if necessary
        marginRight: theme.spacing(1), // Add a right margin to prevent text touching the buttons
    },
    cardContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',

    },
    columnCard: {
        marginBottom: '10px',
        border: '1px solid #ddd',
        borderRadius: '5px',
        backgroundColor: '#fff',
        width: '90%',
        boxSizing: 'border-box',
        display: 'flex',
        height: '30px',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 'auto',
        flexDirection: 'column',
        paddingTop: '10px',
    },
}));

const SavedConfigurationsComponent = ({ selectedConfigCallback, closeHandler, url, options }) => {
    const { user } = useAuth()
    const [configs, setConfigs] = useState([]);
    const [selectedConfig, setSelectedConfig] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false)
    const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false)

    useEffect(() => {
        fetchConfigs()
    }, []);

    const handleSelectConfig = (config) => {
        setSelectedConfig(config);
        selectedConfigCallback(config);
    };



    const handleEditConfig = (e, config) => {
        e.stopPropagation()
        setSelectedConfig(config);
        setEditMode(true);
    };

    const fetchConfigs = () => {
        setLoading(true)
        API.get(`/${url}/configs/all/${user._id}`)
            .then(response => {
                setConfigs(response.data)
                setSelectedConfig(response.data.find(item => item.selected === true))
                setLoading(false)
            })
            .catch(error => {
                console.error(error)
                setLoading(false)
            });
    }

    const handleSaveCreate = (updatedColumns) => {
        API.put(`/${url}/configs/${selectedConfig._id}`, { columns: updatedColumns })
            .then(response => {
                toast.success('Successfully created config')
                setConfigs(configs.map(c => c._id === response.data._id ? response.data : c));
                setEditMode(false);
            })
            .catch(error => {
                toast.error('Failed to create config')
                console.error(error)
            });
    };

    const handleSaveEdit = (updatedColumns, title) => {

        API.put(`/${url}/configs/${selectedConfig._id}`, { columns: updatedColumns, name: title })
            .then(response => {
                fetchConfigs()
                setEditMode(false);
                API.get(`/${url}/configs/all/${user._id}`)
                    .then(response => {
                        toast.success('Successfully edited config')
                        setConfigs(response.data)

                    })
                    .catch(error => {
                        toast.error('Failed to edit config')
                        console.error(error)
                    });
            })
            .catch(error => {
                toast.error('Edit Config Failed')
                console.error(error)
            });
    };

    const handleSelect = (id) => {

        API.put(`/${url}/configs/selected/${id}`, { user: user })
            .then(response => {
                fetchConfigs()
            })
            .catch(error => console.error(error));
    }

    const handleRemove = (config) => {
        setDeleteButtonDisabled(true)
        if (config.selected) {
            toast.error('Cannot remove selected config')
            setDeleteButtonDisabled(false)
            return
        }
        API.delete(`/${url}/configs/${config._id}`, {})
            .then(response => {
                toast.success('Successfully removed config')
                fetchConfigs()
                setEditMode(false);
                setDeleteButtonDisabled(false)
            })
            .catch(error => {
                toast.error('Failed to removed config')
                setDeleteButtonDisabled(false)
                console.error(error)
            });
    }


    const handleCancelEdit = () => setEditMode(false);

    return editMode ? (
        <ChecklistComponent
            configToEdit={selectedConfig}
            callback={handleSaveEdit}
            closeHandler={handleCancelEdit}
            handleSaveEdit={handleSaveEdit}
            handleSaveCreate={handleSaveCreate}
            options={options}
        />
    ) : (
        <div>
            <div className="checklist" style={{ minHeight: '350px', marginTop: '10px', marginBottom: '10px' }}>
                <div className="columns-list" style={{ minHeight: '300px', maxHeight: '350px', overflow: 'auto' }}>
                    <h2>Saved Configurations</h2>
                    {(!loading && configs.length > 0) ? configs.map((config) => (
                        <Card key={config._id} className="column-card draggable" style={{ display: 'flex', cursor: 'pointer' }} onClick={() => handleSelect(config._id)}>
                            <CardContent className='card-content'>
                                <Grid container>
                                    <Grid item xs={2} style={{ textAlign: 'left' }}>
                                        {config.selected === true &&
                                            <IconButton
                                            disabled = {deleteButtonDisabled}
                                                color="primary"
                                                aria-label="select configuration"
                                                component="span"
                                                onClick={() => {
                                                   if(!deleteButtonDisabled) handleSelectConfig(config)
                                                }}
                                            >
                                                <CheckCircleIcon style={{ color: 'green' }} />
                                            </IconButton>
                                        }
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'nowrap' }}>
                                        <Typography className="config-title">{config.name.length >= 20 ? config.name.slice(0, 20) + '...' : config.name}</Typography>
                                    </Grid>
                                    {config?.name?.toLowerCase() !== 'default' && <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <IconButton
                                            color="primary"
                                            aria-label="edit configuration"
                                            component="span"
                                            onClick={(e) => handleEditConfig(e, config)}
                                            style={{ padding: '0px', marginBottom: '3px' }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            disabled={deleteButtonDisabled}
                                            color="primary"
                                            aria-label="edit configuration"
                                            component="span"
                                            style={{ padding: '0px', marginBottom: '3px', marginRight: '2px' }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleRemove(config);
                                            }}
                                        >
                                            <DeleteOutlineIcon style={{ color: 'red' }} />

                                        </IconButton>

                                    </Grid>}
                                </Grid>
                            </CardContent>
                        </Card>
                    )) : <div style={{ height: '100px', width: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Spinner /></div>}
                </div>
            </div>
            <Button
                variant='contained'
                sx={{ bgcolor: '#a3a3ab' }}
                onClick={() => {
                    closeHandler()
                }}>Close</Button>

        </div>
    );
};

export default SavedConfigurationsComponent;
