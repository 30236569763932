import React, { useEffect, useState } from 'react';
import ReportingTable from '../ReportingTable';
import API from 'services/AxiosConfig';
import { formatQueryObj } from '../common';

const ProspectsByReferralPartnerTable = ({filter}:any) => {
const [data,setData] = useState({
  headers:[],
  rows:[]
})
const [loading,setLoading] = useState(false)

useEffect(() => {
  setLoading(true)
  const queryObj = formatQueryObj(filter)
  API.post('/prospects/monthly-prospects-by-partner', queryObj)
      .then(({data}:any) => {
        console.log('the data lives here')
          setData(data?.data)
          setLoading(false)
      })
      .catch(err =>{
          console.log('this is the error!!!!!!!!!!!',err)
          setLoading(false)
      })
}, [filter]);


  return <ReportingTable loading = {loading} setLoading = {setLoading} headers={data?.headers} rows={data?.rows} sumRowLabel="Total New Prospects" sheetName='Prospects by Referral Partner'/>
};

export default ProspectsByReferralPartnerTable;

