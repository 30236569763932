import axios from 'axios';
import API from 'services/AxiosConfig';

export async function getSignedUrl({ key, content_type }) {
  const response = await API.post("/s3/signed_url", {
    key,
    content_type,
  });
  return response.data;
}
export async function uploadFileToSignedUrl(signedUrl, file, contentType, onProgress) {
  try {
    const response = await axios.put(signedUrl, file, {
      onUploadProgress: onProgress,
      headers: {
        "Content-Type": contentType,
      },
    });
    return response;
  } catch (err) {
    console.error(err.response);
    throw err;
  }
}

export async function deleteImage(key, type, dbid) {
  const response = await API.post("/s3/delete", {
    key, type, dbid
  });
  return response;
}
export async function previewDocument({ key }) {
  const response = await API.post("/s3/preview", {
    key
  });
  return response;
}
export async function renameDocument(id, name) {
  const response = await API.post("/s3/rename", {
    id, name
  });
  return response;
}
export async function getDocumets(section) {
  const response = await API.get(`/s3/getDocuments?section=${section}`, {
    locationId: "",
    status: true,
    section: section
  });

  return response;
}
export async function getUploadSections() {
  const response = await API.get("/file-hub/activity/search/Active");
  return response;
}
export async function saveDocument(data) {

  const response = await API.post("/s3/save", {
    category: data.category,
    dtype: data.dtype,
    name: data.name,
    location: data.location,
    comments: data.comments,
    pathUrl: data.pathUrl,
    fileKey: data.fileKey,
    parent: data.parent,
    createdBy: data.createdBy
  });
  return response;
}