import React, { useState, useEffect, useCallback } from 'react';
import { Button } from '@material-ui/core';
import API from 'services/AxiosConfig';
import { toast } from 'react-toastify';
import { Spinner } from 'components';
import { Box } from '@mui/system';

interface CheckboxNodeType {
  label: string;
  checked: boolean;
  children?: CheckboxNodeType[];
  id?: string;
  title_category_id?: number;
  isParent?: boolean;
  parentid?: string;
}

interface CheckboxNodeProps {
  node: CheckboxNodeType;
  onChange: (node: CheckboxNodeType, checked: boolean) => void;
}

const CheckboxNode: React.FC<CheckboxNodeProps> = ({ node, onChange }) => {
  // Handler for when the current node's checkbox changes
  const handleCheckboxChange = (isChecked: boolean) => {
    onChange(node, isChecked);
  };

  return (
    <div >
      <input type="checkbox" checked={node.checked} onChange={(e) => handleCheckboxChange(e.target.checked)} />
      {node.label}
      <div style={{ marginLeft: 20 }}>
        {node.children?.map((child, index) => (
          <CheckboxNode key={index} node={child} onChange={onChange} />
        ))}
      </div>
    </div>
  );
};

interface Props {
  handleEditAccessCloseModal: () => void;
  userId: any
}

function UserEditAccess(props: Props) {
  const { handleEditAccessCloseModal, userId } = props
  const [loading, setLoading] = useState<boolean>(true);
  const [tree, setTree] = useState<CheckboxNodeType[]>([
    {
      id: 'cp',
      label: 'care-planning',
      checked: false,
      children: [
        { id: 'cp_child1', label: 'clients', checked: false, parentid: 'cp' },
        { id: 'cp_child2', label: 'care-partners', checked: false, parentid: 'cp' },
        { id: 'cp_child3', label: 'care-management', checked: false, parentid: 'cp' },
        { id: 'cp_child4', label: 'award-management', checked: false, parentid: 'cp' },
      ],
    },
    {
      id: 'rc',
      label: 'recruiting',
      checked: false,
      children: [
        { id: 'rc_child1', label: 'applicants', checked: false, parentid: 'rc' },
        { id: 'rc_child2', label: 'snapshot', checked: false, parentid: 'rc' },
        { id: 'rc_child3', label: 'status', checked: false, parentid: 'rc' },
      ],
    },
    {
      id: 'mt',
      label: 'marketing',
      checked: false,
      children: [
        { id: 'mt_child1', label: 'referral-partner', checked: false, parentid: 'mt' },
        { id: 'mt_child2', label: 'prospects', checked: false, parentid: 'mt' },
        { id: 'mt_child3', label: 'activities', checked: false, parentid: 'mt' },
      ],
    },
    {
      id: 'rpt',
      label: 'reporting',
      checked: false,
      children: [
        { id: 'rpt_child1', label: 'care-management', checked: false, parentid: 'rpt' },
        { id: 'rpt_child2', label: 'applicants', checked: false, parentid: 'rpt' },
        { id: 'rpt_child3', label: 'prospects', checked: false, parentid: 'rpt' },
        { id: 'rpt_child4', label: 'referral-partner', checked: false, parentid: 'rpt' },
      ],
    },
    {
      id: 'mn',
      label: 'admin',
      checked: false,
      children: [
        { id: 'mn_child1', label: 'user-management', checked: false, parentid: 'mn' },
        { id: 'mn_child2', label: 'Stage Management', checked: false, parentid: 'mn' },
        { id: 'mn_child3', label: 'Award Type Management', checked: false, parentid: 'mn' },
        { id: 'mn_child4', label: 'Activity-Management', checked: false, parentid: 'mn' },
        { id: 'mn_child5', label: 'Location-Management', checked: false, parentid: 'mn' },
        { id: 'mn_child6', label: 'Prospect-Activity', checked: false, parentid: 'mn' },
        { id: 'mn_child7', label: 'Referral-Partners', checked: false, parentid: 'mn' },
        { id: 'mn_child8', label: 'Referral-Activity', checked: false, parentid: 'mn' },
        { id: 'mn_child9', label: 'Lead-Source', checked: false, parentid: 'mn' },
        { id: 'mn_child10', label: 'Stage-Options', checked: false, parentid: 'mn' },
        { id: 'mn_child11', label: 'Lost-client', checked: false, parentid: 'mn' },
        { id: 'mn_child12', label: 'Contact-types', checked: false, parentid: 'mn' },
        { id: 'mn_child13', label: 'Contact-relationship', checked: false, parentid: 'mn' },
        { id: 'mn_child114', label: 'Client-deactivation', checked: false, parentid: 'mn' },
        { id: 'mn_child15', label: 'Care-Partner-Deactivation', checked: false, parentid: 'mn' },
      ],
    },
  ]);

  const updateChildren = (children: CheckboxNodeType[], checked: boolean): CheckboxNodeType[] => {
    return children.map((childNode) => ({
      ...childNode,
      checked,
      children: childNode.children ? updateChildren(childNode.children, checked) : undefined,
    }));
  };


  const handleNodeChange = (changedNode: CheckboxNodeType, isChecked: boolean) => {
    //  console.log('Click', changedNode);
    const updatedTree = tree.map((node: any) => {
      if (node.id === changedNode.parentid) {
        return {
          ...node,
          children: node.children.map((childNode: any) => {
            if (childNode.id === changedNode.id) {
              return { ...childNode, checked: isChecked };
            }
            return childNode;
          }),
          checked: node.children.every((childNode: any) => childNode.id === changedNode.id ? isChecked : childNode.checked),
        };
      }
      if (node.id === changedNode.id) {
        return {
          ...node,
          checked: !node.checked,
          children: updateChildren(node.children, isChecked),
        }
      }
      return node;
    });

    setTree(updatedTree);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = useCallback(() => {
    setLoading(true);
    API.get(`access/${userId}`)
      .then((rsp: any) => {
        if (rsp && rsp?.data) {
          if (rsp.data?.data?.tree) {
            let data = JSON.parse(rsp.data.data.tree)
            setTree(data)
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error('Failed to load edit access.');
      });
  }, []);



  // Handle form submission
  const handleSubmit = () => {
    setLoading(true);
    API.put(`access/${userId}`, { treeData: JSON.stringify(tree) })
      .then((rsp: any) => {
        setLoading(false);
        toast.success('Successfully user edit access.');

      })
      .catch((error) => {
        setLoading(false);
        toast.error('Failed to load edit access.');
      });
  };

  const hundleClose = () => {
    handleEditAccessCloseModal()
  }


  return (

    <div>
      {loading && <Spinner />}
      {tree.map((node, index) => (
        <CheckboxNode key={index} node={node} onChange={handleNodeChange} />
      ))}
      <Box>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
          <Button variant="contained" sx={{ bgcolor: '#a3a3ab' }} type="button" onClick={hundleClose}>Cancel</Button>
          <Button variant="contained" sx={{ bgcolor: '5b73e8' }} type="button" style={{ marginLeft: '55%' }} onClick={handleSubmit}>
            Submit
          </Button></div>
      </Box>
    </div>

  );
};

export default UserEditAccess;
