import React, { useEffect, useRef, useState } from 'react';
import ReportingTable from '../ReportingTable';
import API from 'services/AxiosConfig';
import ReferralReportingTable from '../ReferralReportingTable';
import { formatQueryObj } from '../common';

const ReferralsByTypeTable = ({ filter, delta = false }: any) => {
    const [data, setData] = useState({ headers: [], rows: [] })
    const [loading, setLoading] = useState(false)
    const currentQueryRef = useRef(0);
    useEffect(() => {
        const queryObj = formatQueryObj(filter)
        setLoading(true)
        const queryNumber = ++currentQueryRef.current;
        API.post('referral-partners/monthly-types', queryObj)
            .then(({ data }: any) => {
                if (currentQueryRef.current === queryNumber) {
                    setData(data.data)
                    setLoading(false);
                }
            })
            .catch(err => {
                console.log(err)
                if (currentQueryRef.current === queryNumber) {
                    setLoading(false);
                }
            })
    }, [filter]);


    return <ReferralReportingTable loading={loading} delta={delta} headers={data?.headers || []} rows={data?.rows || []} sumRowLabel="Grand Totals" sheetName='Prospects by Activity & Referrals Count by Referral Partner' />
};

export default ReferralsByTypeTable;


