import { useState, } from 'react';
import { Card, CardHeader, Modal, Spinner } from 'components';
import ApplicantActivityTabPanel from 'views/Recruiting/ApplicantActivtyTabPanel';
import ResizableTable from 'views/ResizableTable';
import CompaniesResizableTable from 'views/CompaniesResizableTable';
import ClientsReportingTables from './ClientsReportingTables';
import CpReportingTables from './CpReportingTables';

export const CareManagementTabs = ({ filter, resetFilter }: any) => {
const [type,setType] = useState('Clients')
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [openModal, setOpenModal] = useState(false)
  const queryParameters = new URLSearchParams(window.location.search);
  const tab = queryParameters.get("type")

  const tableToRender = (currentActivity: any) => {
    return currentActivity === 'Clients' ? <ClientsReportingTables filter = {filter}/>: <CpReportingTables filter = {filter}/> 
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }
  return (
    <div style = {{marginTop:'100px', width:'1600px'}}>
        {loading ? (
          <Spinner />
        ) : (
          <ApplicantActivityTabPanel activityType={tab || type} setType={setType} resetFilter={resetFilter} tabs={['Clients', 'Care Partners']} TableToRender={(currentActivity: any) => tableToRender(currentActivity)}
          />
        )}

    </div>
  );
};
function useQuery() {
  throw new Error('Function not implemented.');
}

