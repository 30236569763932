import React from 'react'
import {  Radar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
ChartJS.register(...registerables);


const RadarChart = (props: any) => {

    const {RadarData,RadarOptions } = props

    return (
        <Radar  data={RadarData} options={RadarOptions} />
      );
}

export default RadarChart