import { Layout } from 'components'
import { useAuth } from 'hooks'
import { useEffect, useState } from 'react';
import { FilterHeader } from 'views'
import { ProspectTable } from 'views/Prospects/ProspectsTable'
import { useFilter } from '../ReferralPartners/ReferralFilterContext'
import { fetchProspectStages } from 'lib';
import { Container } from '@mui/system';
function ProspectsChild() {
  // const { user } = useAuth()

  const { prospectFilter, updateProspectFilter } = useFilter()
  const [stages, setStages] = useState<any[]>([]);

  useEffect(() => {
    fetchProspectStages(setStages);
  }, []);
  return (
    <Layout>
      <Container maxWidth="xl" sx={{ pt: 0 }}>
        <FilterHeader type="prospects" setFilter={updateProspectFilter} filter={prospectFilter} options={stages} />
        <ProspectTable filter={prospectFilter} setFilter={updateProspectFilter} title="Prospects" /></Container>
    </Layout>
  )
}

export default ProspectsChild
