import { Container } from '@mui/system';
import { Layout } from 'components';
import { ApplicantProgress } from 'views';
import ActivityTabs from 'views/Activity/ActivityTabs';

export const StageManagement = (): JSX.Element => {

  const TABS = [
    {
      label: 'Applicant',
      value: 'Applicant',

      Children: <ApplicantProgress URLPATH="/applicants/progress" /*successres="applicant"*/ title="Applicant Progress"  admin = {true}  add/>,
    },
    {
      label: 'Prospect',
      value: 'Prospect',
      Children: <ApplicantProgress URLPATH="/prospects/progress" /*successres="prospects"*/ title="Prospect Progress" admin = {true}   add/>,
    },
   
  ];

  return (
    <Layout>
        <Container maxWidth="xl" sx={{ pt: 3, mt: 20, width:'100%' }}>
        <ActivityTabs tabs={TABS || ['Activities', 'Prospects']} HeaderTitle={"Stage Management"} ></ActivityTabs>
        
        </Container>
    </Layout>
  );
};
