import { Layout } from '../../components'
import { useState } from 'react'
import { FilterHeader } from 'views'
import { useAuth } from 'hooks'
import ApplicantsTotalNewBarChart from '../../views/Reporting/Applicants/ApplicantsTotalNewBarChart'
import ApplicantsNewAndHired from '../../views/Reporting/Applicants/ApplicantsNewAndHired'
import ApplicantsHiredAndLost from '../../views/Reporting/Applicants/ApplicantsHiredAndLost'
import ApplicantsWinPercentage from '../../views/Reporting/Applicants/AppplicantsWinPercentage'
import ApplicantsReportingTable from '../../views/Reporting/Applicants/ApplicantsReportingTable'
import ApplicantsBySourceTable from '../../views/Reporting/Applicants/ApplicantsBySourceTable'
import ApplicantsLostReasonTable from '../../views/Reporting/Applicants/ApplicantsLostReasonTable'

function ApplicantsReporting(props: any) {

    const { user } = useAuth();
    const [filter, setFilter] = useState({
        location: { id: user ? user?.location._id : '0', value: user ? user?.location?.location : 'All' },
        timeFrame:{id: 'last6Months', value: 'Last 6 Months' }
    })

    return (
        <Layout>
            <div style={{ marginTop: '10%', marginBottom: '2%' }}>
                <FilterHeader type='prospectsReporting' setFilter={setFilter} filter={filter} />
            </div>
            <div style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridGap: '30px',
                placeItems: 'center',
                paddingLeft: '130px',
                paddingRight: '100px'
            }}>
                <ApplicantsHiredAndLost filter={filter} />
                <ApplicantsNewAndHired filter={filter} />
                <ApplicantsTotalNewBarChart filter={filter} />
                <ApplicantsWinPercentage filter={filter} />
            </div>
            <div style={{
                display: 'grid',
                gridTemplateColumns: '1fr',
                gridGap: '20px',
                placeItems: 'center',
                paddingLeft: '130px',
                paddingRight: '100px'
            }}>
                <ApplicantsReportingTable filter={filter} />
                <ApplicantsBySourceTable filter={filter} />
                <ApplicantsLostReasonTable filter={filter} />
            </div>
        </Layout>
    )
}

export default ApplicantsReporting