import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDocumentPreview } from "../Uploader/redux/actions/documentActions";
import {
    Container,
    Typography,
    Card,
    CardContent,
    CardMedia,
    CircularProgress,
    Box,
    Button,
} from "@mui/material";

const DocumentViewer = () => {
    const dispatch = useDispatch();

    const { docsToView, previewData, fetchDocPreviewLoading, error } = useSelector(state => ({
        docsToView: state.documents?.documents?.find(doc => doc?._id === state.documents.selectedNode),
        selectedNode: state.documents.selectedNode,
        previewData: state.documents.previewData,
        fetchDocPreviewLoading: state.documents.fetchDocPreviewLoading,
        error: state.documents.error // Capture error state
    }));

    const [docsEmbedUrl, setDocsEmbedUrl] = useState(null);

    useEffect(() => {
        if (docsToView && docsToView.dtype!=="folder") {
            dispatch(fetchDocumentPreview(docsToView.fileKey));
        }
    }, [docsToView, dispatch]);

    useEffect(() => {
        if (previewData) {
            setDocsEmbedUrl(previewData.result);
        }
    }, [previewData]);

    if (!docsToView) return <div></div>;

    const { dtype, name } = docsToView;

    const renderContent = () => {
        if (fetchDocPreviewLoading) {
            return (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress />
                </Box>
            );
        }

        if (error) {
            return (
                <Typography color="error">
                    Error fetching document preview: {error.message || error}
                </Typography>
            );
        }

        return (
            <>
            {dtype !== 'folder' &&
            <Card className="test__" sx={{ marginTop: 2, width: '100%'}}>
                <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 , width:"100%"}}>
                        {dtype.startsWith('image/') ? (
                            <CardMedia
                                component="img"
                                src={docsEmbedUrl}
                                alt={name || 'Image'}
                                sx={{ maxWidth: '100%', height: 'auto', borderRadius: 2 }}
                            />
                        ) : dtype.startsWith('application/pdf') ? (
                            <embed
                                src={docsEmbedUrl}
                                type="application/pdf"
                                width="100%"
                                // height="600px"
                                // height= 'calc(100vh - 140px)'
                                style={{ border: 'none', borderRadius: 2,height: 'calc(100vh - 130px)' }}
                            />
                        ) : dtype.startsWith('application/') ? (
                            <Box textAlign="center">
                                <Typography variant="body1" gutterBottom>
                                    This document type is not displayed inline.
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    href={docsEmbedUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{ marginTop: 1 }}
                                >
                                    View or Download Document
                                </Button>
                            </Box>
                        ) : (
                            <Typography variant="body1">Unsupported file type</Typography>
                        )}
                    </Box>
                </CardContent>
            </Card>
            }
            </>
        );
    };

    return (
        <Box maxWidth="md">
            {renderContent()}
        </Box>
    );
};

export default DocumentViewer;
