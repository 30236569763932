import {
  FETCH_DOCUMENTS_REQUEST,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_FAILURE,
  UPLOAD_DOCUMENT_REQUEST,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAILURE,
  DELETE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_FAILURE,
  FETCH_DOC_PREVIEW_REQUEST,
  FETCH_DOC_PREVIEW_SUCCESS,
  FETCH_DOC_PREVIEW_FAILURE,
  RENAME_DOC_REQUEST,
  RENAME_DOC_SUCCESS,
  RENAME_DOC_FAILURE,
  SAVE_DOC_REQUEST,
  SAVE_DOC_SUCCESS,
  SAVE_DOC_FAILURE,
  SELECTED_NODE,
  OBJECT_SELECTED_NODE,
  UPLOAD_DOCUMENT_PROGRESS,
  ZERO_LEVEL_DATA,
  ZERO_LEVEL_DATA_REQUEST,
  ZERO_LEVEL_DATA_SUCCESS,
  ZERO_LEVEL_DATA_FAILURE,
  FETCH_FILE_SECTIONS_REQUEST,
  FETCH_FILE_SECTIONS_SUCCESS,
  FETCH_FILE_SECTIONS_FAILURE
} from './actionTypes';

import {
  getSignedUrl,
  uploadFileToSignedUrl,
  previewDocument as fetchDocumentPreviewApi,
  renameDocument as renameDocumentApi,
  getDocumets as fetchDocumentsApi,
  saveDocument as saveDocumentApi,
  getUploadSections as fetchUploadSectionApi,
  deleteImage
} from '../../api';

import { fileKey } from './getFileKey';

// Fetch Documents
export const fetchDocuments = (section) => async (dispatch) => {
  dispatch({ type: FETCH_DOCUMENTS_REQUEST });
  try {
    const response = await fetchDocumentsApi(section);
    const data = response.data.result;
    if (response.status === 200) {
      dispatch({ type: FETCH_DOCUMENTS_SUCCESS, payload: data });
    } else {
      dispatch({ type: FETCH_DOCUMENTS_FAILURE, error: 'Unexpected response status' });
    }
  } catch (error) {
    dispatch({ type: FETCH_DOCUMENTS_FAILURE, error })
    console.log('Failed to save document. Please check your connection and try again.');
  }
};
// Fetch Documents
export const fetchUploadSections = () => async (dispatch) => {
  dispatch({ type: FETCH_FILE_SECTIONS_REQUEST });
  try {
    const response = await fetchUploadSectionApi();
    const data = response.data.data;
    if (response.status === 200) {
      dispatch({ type: FETCH_FILE_SECTIONS_SUCCESS, payload: data });
    } else {
      dispatch({ type: FETCH_FILE_SECTIONS_FAILURE, error: 'Unexpected response status' });
    }
  } catch (error) {
    dispatch({ type: FETCH_FILE_SECTIONS_FAILURE, error })
    console.log('Failed to save document. Please check your connection and try again.');
  }
};
// Upload Document
export const uploadFile = (signedUrl, file, contentType) => async (dispatch) => {
  try {
    const uploadResponse = await uploadFileToSignedUrl(
      signedUrl,
      file,
      contentType,
      (progress) => {
        dispatch({ type: UPLOAD_DOCUMENT_PROGRESS, payload: (progress.loaded / progress.total) * 100 });
      }
    );

    return uploadResponse;
  } catch (error) {
    console.error('File upload failed:', error);
    throw error;
  }
};

export const uploadDocument = (file, selectedNode, name, selectedValue, id = "") => async (dispatch) => {
  dispatch({ type: UPLOAD_DOCUMENT_REQUEST });
  try {
    const { key, content_type } = fileKey(file);
    const response = await getSignedUrl({ key, content_type });

    const signedUrl = response.data?.signedUrl;
    const fileLink = response.data?.fileLink;

    if (signedUrl && fileLink) {
      const uploadResponse = await dispatch(uploadFile(signedUrl, file, content_type));

      const finalData = {
        category: selectedValue,
        dtype: content_type,
        location: "63ac458916769ea5183d3441",
        comments: "",
        pathUrl: fileLink,
        fileKey: key,
        parent: selectedNode || '',
        name: name,
        createdBy: id,
      };

      await dispatch(saveDocument(finalData));

      if (uploadResponse.status === 200) {
        dispatch({ type: UPLOAD_DOCUMENT_SUCCESS, payload: uploadResponse.data });
        dispatch(fetchDocuments());
      } else {
        throw new Error('Unexpected response status');
      }
    } else {
      throw new Error('Signed URL or file link is missing');
    }
  } catch (error) {
    dispatch({ type: UPLOAD_DOCUMENT_FAILURE, error: error.message || 'Upload failed' });
  }
};



// Delete Document
export const deleteDocument = (key, type, id) => async (dispatch) => {
  dispatch({ type: DELETE_DOCUMENT_REQUEST });
  try {
    const response = await deleteImage(key, type, id);
    if (response.status === 200) {
      dispatch({ type: DELETE_DOCUMENT_SUCCESS, payload: response.data });
      dispatch(fetchDocuments());
    }
    else {
      dispatch({ type: DELETE_DOCUMENT_FAILURE, error: 'Unexpected response status' });
      console.log('Failed to delete document. Please try again.');
    }
  } catch (error) {
    dispatch({ type: DELETE_DOCUMENT_FAILURE, error });
    console.log('Failed to save document. Please check your connection and try again.');
  }
};

// Fetch Document Preview
export const fetchDocumentPreview = (documentId) => async (dispatch) => {
  dispatch({ type: FETCH_DOC_PREVIEW_REQUEST });
  try {
    const previewData = await fetchDocumentPreviewApi({ key: documentId });
    if (previewData.status === 200 && (previewData.statusText === "OK" || previewData.statusText === "")) {
      dispatch({ type: FETCH_DOC_PREVIEW_SUCCESS, payload: previewData.data });
    }
  } catch (error) {
    dispatch({ type: FETCH_DOC_PREVIEW_FAILURE, error });
  }
};

// Rename Document
export const renameDocument = (documentId, newName) => async (dispatch) => {
  dispatch({ type: RENAME_DOC_REQUEST });
  try {
    const updatedDocument = await renameDocumentApi(documentId, newName);
    if (updatedDocument.data.status === 200) {
      dispatch({ type: RENAME_DOC_SUCCESS, payload: updatedDocument });
      dispatch(fetchDocuments());
      console.log('Edited successfully!');
    }
    else {
      dispatch({ type: RENAME_DOC_FAILURE, error: 'Unexpected response status' });
      console.log('Failed to save document. Please try again.');
    }
  } catch (error) {
    dispatch({ type: RENAME_DOC_FAILURE, error });
    console.error('Error saving document:', error.message);
  }
};

export const saveDocument = (documentData) => async (dispatch) => {
  dispatch({ type: SAVE_DOC_REQUEST });

  try {
    const response = await saveDocumentApi(documentData);
    if (response.status === 200) {
      dispatch({ type: SAVE_DOC_SUCCESS, payload: response.data });
      console.log('Document saved successfully!');
    } else {
      // Handle unexpected response status
      dispatch({ type: SAVE_DOC_FAILURE, error: 'Unexpected response status' });
      console.log('Failed to save document. Please try again.');
    }
  } catch (error) {
    // Handle errors from the request
    console.error('Error saving document:', error.response ? error.response.data : error.message);
    dispatch({ type: SAVE_DOC_FAILURE, error: error.response ? error.response.data : error.message });
  }
};

export const setSelectedNode = (nodeId) => {
  return {
    type: SELECTED_NODE,
    payload: nodeId
  };
};

export const showFolderList = (data) => ({
  type: OBJECT_SELECTED_NODE,
  payload: data,
});


export const setZeroLevelData = (data) => async (dispatch) => {
  dispatch({ type: ZERO_LEVEL_DATA_REQUEST });
  try {
    dispatch({ type: ZERO_LEVEL_DATA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ZERO_LEVEL_DATA_FAILURE, error });
  }
};
